import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { getTipoUsuario, logout } from "../service/authService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  nomepag: {
    color: "inherit",
  },
}));

export default function ButtonAppBar({ title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          {title && (
            <Typography variant="h6" className={classes.nomepag}>
              {title}
            </Typography>
          )}
          <ListItem
            button
            style={{
              width: 25,
              height: 25,
              justifyItems: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ListItemIcon
              onClick={logout}
              style={{
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ExitToAppIcon style={{ color: "#192d4d" }} />
            </ListItemIcon>
          </ListItem>
          <Typography variant="h6" className={classes.title}></Typography>
          <Typography color="inherit">STR TRANSPORTES LOGISTICA</Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
