import React, { useState, useEffect } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { colors, Modal } from "@material-ui/core"; // Exemplo com Material-UI

import Paper from "@material-ui/core/Paper";
import Style_login from "../style/style_login.css";
import { BsSearch } from "react-icons/bs";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import InputBase from "@material-ui/core/InputBase";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";

import api from "../api";
import {
  getProgramacaoCargaid,
  putProgramacao,
  deleteProgramacaoentrega,
} from "../service/CRUD_Service";
import { Cores } from "../style/Color";

import Barra from "./barra_motorista";

export default function ProgramacaoCargaEdit({ history }) {
  // Definição dos estados usando useState

  const { id } = history.location.state;
  const [idprogramacao, setIdProgramacao] = useState("");
  const [programacaocarga, setIdProgramacaocarga] = useState("");
  const [idmotorista, setIdMotorista] = useState("");
  const [nomeMotorista, setNomeMotorista] = useState("");
  const [idveiculotrator, setIdVeiculoTrator] = useState("");
  const [nomeVeiculoTrator, setNomeVeiculoTrator] = useState("");
  const [idunidadecarga, setIdUnidadeCarga] = useState("");
  const [nomeunidadecarga, setNomeUnidadeCarga] = useState("");
  const [names, setNames] = useState([]); // Armazena os dados retornados da API
  const [veiculotrator, setVeiculoTrator] = useState([]);
  const [unidadeCarga, setUnidadeCarga] = useState([]);
  const [listaEntregas, setListaEntregas] = useState([]);
  const [origem, setOrigem] = useState([]);
  const [idorigem, setIdOrigem] = useState("");
  const [nomeOrigem, setNomeOrigem] = useState("");
  const [destinos, setDestinos] = useState([]);
  const [iddestinos, setIdDestinos] = useState("");
  const [nomedestinos, setNomeDestinos] = useState("");
  const [perfil, setPerfil] = useState([]);
  const [idperfil, setIdPerfil] = useState("");
  const [nomePerfil, setNomePerfil] = useState("");
  const [qtdpl, setQtdPl] = useState("");
  const [loading, setLoading] = useState(true); // Para exibir carregamento enquanto a API responde
  const [showModal, setShowModal] = useState(false); // Controla a visibilidade do modal
  const [modalVeiculoAberto, setModalVeiculoAberto] = useState(false); // Controle do modal de veículo
  const [showmodalunidadecarga, setShowModalUnidadeCarga] = useState(false);
  const [showModalOrigem, setShowModalOrigem] = useState(false);
  const [showModalDestinos, setShowModalDestinos] = useState(false);
  const [showModalPerfil, setShowModalPerfil] = useState(false);
  const [filtroPlaca, setFiltroPlaca] = useState("");
  const [filtroUnidadecarga, setFiltroUnidadeCarga] = useState("");
  const [filtramotorista, setFiltraMotorista] = useState("");
  const [filtroOrigem, setFiltroOrigem] = useState("");
  const [filtroDestinos, setFiltroDestinos] = useState("");
  const [filtroPerfil, setFiltroPerfil] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [OrigErro, setOrigErro] = useState("");
  const [refresh, setRefresh] = useState(false); // Estado para forçar atualização
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(null);

  useEffect(() => {
    getProgramacaoCargaid(id)
      .then((res) => {
        setIdProgramacaocarga(res);
        setIdMotorista(res.idmotoristas.id);
        setNomeMotorista(res.idmotoristas.nome);
        setIdVeiculoTrator(res.veiculotrator.id);
        setNomeVeiculoTrator(res.veiculotrator.placa);
        setIdUnidadeCarga(res.unidadecarga.id);
        setNomeUnidadeCarga(res.unidadecarga.placa);
        setListaEntregas(res.entregas);
        console.log(res.entregas);
      })
      .catch((err) => setOrigErro(err));
  }, [id, refresh]);

  let Perfil = [
    {
      id: "1",
      perfil: "Normal",
    },
    {
      id: "2",
      perfil: "Horti",
    },
    {
      id: "3",
      perfil: "Frios",
    },
    {
      id: "4",
      perfil: "Panificacao Frios",
    },
    {
      id: "5",
      perfil: "Panificacao Seca",
    },
  ];

  // Função para fechar o Snackbar de sucesso
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // Redirecionar após o tempo do Snackbar
    setTimeout(() => {
      // window.location = "/ListaProgramacao"; // Redireciona para a página desejada
    }, 2000); // Tempo de duração do Snackbar (em milissegundos)

    setOpenSnackbar(false);
  };

  // Função para fechar o Snackbar de erro
  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  // Função para preencher a lista
  const preencherLista = (event) => {
    event.preventDefault();

    const payloadEntrega = {
      fkprogramacaocarga: id,
      idorigem: idorigem,
      iddestino: iddestinos,
      perfil: nomePerfil,
      qtdpallet: qtdpl,
    };

    api
      .post("/programacaoentregas", payloadEntrega)
      .then(() => {
        setRefresh((prev) => !prev); // Alterna o valor de refresh
        // Exibe o Snackbar de sucesso
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setOrigErro(err);
        console.error("Erro ao salvar:", err);
      });
  };

  const removerItem = (idItem) => {
    setIdToRemove(idItem);
    setConfirmOpen(true); // Exibe o Alert de confirmação
  };

  const confirmRemove = () => {
    deleteProgramacaoentrega(idToRemove) // Substitua por sua função que realiza o DELETE na API
      .then(() => {
        // Atualiza a lista local removendo o item excluído
        setRefresh((prev) => !prev);

        // Exibe Snackbar de sucesso
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setOrigErro(err);
        console.error("Erro ao excluir item:", err);
      })
      .finally(() => {
        setConfirmOpen(false); // Fecha o Alert após a exclusão
        setIdToRemove(null);
      });
  };

  const cancelRemove = () => {
    setConfirmOpen(false); // Fecha o Alert sem excluir
    setIdToRemove(null);
  };

  // Manipulador para atualizar o estado com base no evento de input
  const changeHandler = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "idorigem":
        setIdOrigem(value);
        break;
      case "nomeOrigem":
        setNomeOrigem(value);
        break;
      case "iddestinos":
        setIdDestinos(value);
        break;
      case "nomedestinos":
        setNomeDestinos(value);
        break;
      case "nomePerfil":
        setNomePerfil(value);
        break;
      case "qtdpl":
        setQtdPl(value);
        break;
      default:
        break;
    }
  };

  // Função para submeter o formulário
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const payloadCarga = {
        idmotorista,
        idveiculotrator,
        idunidadecarga,
      };

      const responseCarga = await api.post("/programacaocarga", payloadCarga);
      const idProgramacaoCarga = responseCarga.data.id;

      const entregasPayload = listaEntregas.map((entrega) => ({
        fkprogramacaocarga: idProgramacaoCarga,
        idorigem: entrega.idorigem,
        iddestino: entrega.iddestinos,
        perfil: entrega.nomePerfil,
        qtdpallet: entrega.qtdpl,
      }));

      for (const entrega of entregasPayload) {
        await api.post("/programacaoentregas", entrega);
      }

      setOpenSnackbar(true);
    } catch (error) {
      console.error(
        "Erro ao salvar a programação de carga ou entregas:",
        error
      );
      alert("Erro ao salvar. Verifique os dados e tente novamente.");
    }
  };

  const toEditProgramacao = (event) => {
    event.preventDefault();

    const payloadCarga = {
      idmotorista,
      idveiculotrator,
      idunidadecarga,
    };

    putProgramacao(id, payloadCarga)
      .then(() => history.push("/ListaProgramacao"))
      .catch((err) => setOrigErro(err));
  };

  // Função para buscar motoristas
  const fetchMotoristas = () => {
    setLoading(true);
    api
      .get("/MotoristamanutencaoControllers")
      .then((res) => {
        setNames(res.data);
        setLoading(false);
        setShowModal(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // Selecionar motorista
  const handleSelectMotorista = (id, nome) => {
    setIdMotorista(id);
    setNomeMotorista(nome);
    setShowModal(false);
  };

  // Função para buscar veículos trator
  const fetchVeiculotrator = () => {
    setLoading(true);
    api
      .get("/veiculos")
      .then((res) => {
        setVeiculoTrator(res.data);
        setLoading(false);
        setModalVeiculoAberto(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // Função para buscar veículos trator
  const fetchVeiculounidadecarga = () => {
    setLoading(true);
    api
      .get("/veiculos")
      .then((res) => {
        setUnidadeCarga(res.data);
        setLoading(false);
        setShowModalUnidadeCarga(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleSelectVeiculotrator = (id, nome) => {
    setIdVeiculoTrator(id);
    setNomeVeiculoTrator(nome);
    setModalVeiculoAberto(false);
  };

  const handleSelectunidadecarga = (id, nome) => {
    setIdUnidadeCarga(id);
    setNomeUnidadeCarga(nome);
    setShowModalUnidadeCarga(false);
  };

  // Função para buscar veículos trator
  const fetchOrigens = () => {
    setLoading(true);
    api
      .get("/origem")
      .then((res) => {
        setOrigem(res.data);
        setLoading(false);
        setShowModalOrigem(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleSelectOrigem = (id, nome) => {
    setIdOrigem(id);
    setNomeOrigem(nome);
    setShowModalOrigem(false);
  };

  const handleFiltroOrigemChange = (e) => {
    setFiltroOrigem(e.target.value);
  };
  const handlefiltroDestinosChange = (e) => {
    setFiltroDestinos(e.target.value);
  };

  // Função para buscar veículos trator
  const fetchDestinos = () => {
    setLoading(true);
    api
      .get("/destino")
      .then((res) => {
        setDestinos(res.data);
        setLoading(false);
        setShowModalDestinos(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const handleSelectDestinos = (id, nome) => {
    setIdDestinos(id);
    setNomeDestinos(nome);
    setShowModalDestinos(false);
  };

  const fetchPerfil = () => {
    setLoading(true);

    setTimeout(() => {
      setPerfil(Perfil); // Define os dados como sendo o array Perfil
      setLoading(false);
      setShowModalPerfil(true); // Exibe modal após carregar os dados
    }, 500);
  };
  const handleSelectPerfil = (id, nome) => {
    setIdPerfil(id);
    setNomePerfil(nome);
    setShowModalPerfil(false);
  };

  const handlefiltroPerfilChange = (e) => {
    setFiltroPerfil(e.target.value);
  };
  const FecharModalMotorista = (e) => {
    setShowModal(false);
  };

  const FecharModalVeiculotrator = (e) => {
    setModalVeiculoAberto(false);
  };
  const FecharModalunidadecarga = (e) => {
    setShowModalUnidadeCarga(false);
  };

  return (
    <body className={Style_login.body}>
      <div>
        <Barra />
        <div style={{ marginTop: 50, padding: 20 }}>
          <Paper
            variant="outlined"
            elevation={4}
            style={{
              backgroundColor: "#fff",

              //   maxHeight: 800, // Limita a altura máxima
              overflow: "hidden", // Evita rolagem externa
              margin: "auto", // Centraliza o Paper na tela
              borderRadius: "8px",
              display: "flex", // Torna o conteúdo interno flexível
              flexDirection: "column", // Organiza os elementos verticalmente
            }}
          >
            <form
              style={
                {
                  // backgroundColor: "red",
                }
              }
              onSubmit={submitHandler}
            >
              <div
                style={{
                  justifyItems: "center",
                }}
              >
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={2000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      position: "fixed",
                      left: "50%",
                      bottom: 20,
                      transform: "translateX(-50%)",
                      zIndex: 9999,
                    }}
                  >
                    Processo executado com sucesso!
                  </Alert>
                </Snackbar>
                {/* Snackbar de erro */}
                {confirmOpen && (
                  <Alert
                    variant="filled"
                    severity="info"
                    sx={{
                      position: "fixed",
                      top: "50%", // Alinha o alerta verticalmente no centro
                      left: "50%", // Alinha o alerta horizontalmente no centro
                      transform: "translate(-50%, -50%)", // Ajusta para centralizar completamente
                      zIndex: 9999,
                      width: "90%", // Opcional: ajusta o tamanho do alerta
                      maxWidth: 600, // Opcional: define largura máxima
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginRight: 45 }}>
                        Tem certeza que deseja excluir este item?
                      </span>
                      <p></p>
                      <div>
                        <button
                          onClick={confirmRemove}
                          style={{
                            marginRight: 8,
                            background: "#d32f2f",
                            color: "#fff",
                            border: "none",
                            padding: "6px 12px",
                            cursor: "pointer",
                            borderRadius: 4,
                          }}
                        >
                          Confirmar
                        </button>
                        <button
                          onClick={cancelRemove}
                          style={{
                            background: "#1976d2",
                            color: "#fff",
                            border: "none",
                            padding: "6px 12px",
                            cursor: "pointer",
                            borderRadius: 4,
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </Alert>
                )}

                <InputLabel
                  style={{ fontSize: 25, marginTop: 15 }}
                  id="demo-simple-select-helper-label"
                >
                  Alteracao de Programacao
                </InputLabel>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 15, // Espaçamento entre linhas
                  //  justifyContent: "space-around",
                  marginTop: 35,
                }}
              >
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 10,
                  }}
                >
                  <InputBase
                    style={{
                      width: 80,
                      backgroundColor: Cores.bgInpubase,
                      height: 35,
                    }}
                    className="form-control text-center input-text"
                    placeholder="00"
                    type="number"
                    name="Motorista"
                    value={idmotorista}
                    onChange={changeHandler}
                  />

                  <InputBase
                    style={{
                      width: 370,
                      width: 250,
                      height: 35,
                      backgroundColor: Cores.bgInpubase,
                    }}
                    className="form-control text-center input-text"
                    placeholder="Selecione um motorista"
                    value={nomeMotorista}
                    InputProps={{
                      readOnly: true, // Campo somente leitura
                    }}
                  />

                  {/* Botão para abrir o modal */}
                  <Button
                    variant="contained"
                    onClick={fetchMotoristas}
                    style={{
                      marginLeft: -6,
                      height: 34,
                      backgroundColor: Cores.bgBotoesNovo,
                      color: Cores.clBotoesNovo,
                    }}
                  >
                    <BsSearch
                      color={Cores.clBotoesNovo}
                      onClick={fetchMotoristas}
                      style={{ fontSize: 20 }}
                    />
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 15, // Espaçamento entre linhas
                  marginLeft: 10,
                  marginTop: 15,
                }}
              >
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                  }}
                >
                  <InputBase
                    style={{
                      width: 80,
                      height: 35,
                      backgroundColor: Cores.bgInpubase,
                    }}
                    placeholder="00"
                    className="form-control text-center input-text"
                    type="number"
                    name="Placa trator"
                    value={idveiculotrator}
                    onChange={changeHandler}
                  />

                  {/* Nome do Motorista */}
                  <InputBase
                    style={{
                      width: 250,
                      height: 35,
                      backgroundColor: Cores.bgInpubase,
                    }}
                    className="form-control text-center input-text"
                    placeholder="Selecione um veiculo trator"
                    value={nomeVeiculoTrator}
                    InputProps={{
                      readOnly: true, // Campo somente leitura
                    }}
                  />

                  {/* Botão para abrir o modal */}
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: -7,
                      height: 34,
                      backgroundColor: Cores.bgBotoesNovo,
                      color: Cores.clBotoesNovo,
                    }}
                  >
                    <BsSearch
                      color={Cores.clBotoesNovo}
                      onClick={fetchVeiculotrator}
                      style={{ fontSize: 20 }}
                    />
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 15, // Espaçamento entre linhas
                  marginTop: 15,
                  marginLeft: 10,
                }}
              >
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                  }}
                >
                  <InputBase
                    style={{
                      width: 80,
                      height: 35,

                      backgroundColor: Cores.bgInpubase,
                    }}
                    placeholder="00"
                    className="form-control text-center input-text"
                    type="number"
                    name="Placa Unidade carga"
                    value={idunidadecarga}
                    onChange={changeHandler}
                  />
                </div>

                <InputBase
                  style={{
                    width: 250,
                    height: 35,
                    marginLeft: -4,
                    backgroundColor: Cores.bgInpubase,
                  }}
                  placeholder="Selecione unidade carga"
                  className="form-control text-center input-text"
                  value={nomeunidadecarga}
                  InputProps={{
                    readOnly: true, // Campo somente leitura
                  }}
                />

                {/* Botão para abrir o modal */}
                <Button
                  variant="contained"
                  style={{
                    marginLeft: -13,

                    height: 34,
                    backgroundColor: Cores.bgBotoesNovo,
                    color: Cores.clBotoesNovo,
                  }}
                >
                  <BsSearch
                    color={Cores.clBotoesNovo}
                    onClick={fetchVeiculounidadecarga}
                    style={{ fontSize: 20 }}
                  />
                </Button>
              </div>

              <br></br>
              <div style={{ padding: 10 }}>
                <Paper
                  style={{
                    //  maxHeight: 800, // Limita a altura máxima
                    width: "100%", // Adapta-se à largura da div pai
                    maxHeight: "100%", // Adapta-se à altura da div pai
                    minHeight: 350,
                    marginTop: 25,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: Cores.subcabecalho,
                      height: 15,
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                    }}
                  ></div>

                  <TableContainer
                    style={{
                      maxHeight: "300px", // Altura máxima definida
                      overflowY: "auto", // Habilita a barra de rolagem vertical
                    }}
                  >
                    <br></br>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Origem
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Destino
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Perfil
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Qtd Pallet
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Opcoes
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {listaEntregas.map((item, id) => (
                        <TableBody key={item.id}>
                          <TableRow role="checkbox" key={item.id}>
                            <TableCell align="center">
                              {item.origem.descricao}
                            </TableCell>
                            <TableCell align="center">
                              {item.destinos.razao}
                            </TableCell>
                            <TableCell align="center">{item.perfil}</TableCell>
                            <TableCell align="center">
                              {item.qtdpallet}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                onClick={() => removerItem(item.id)}
                                style={{
                                  backgroundColor: Cores.bgBotoesExcluir,
                                  color: Cores.clBotoesExcluir,
                                  width: 150,
                                }}
                              >
                                Excluir
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 15, // Espaçamento entre linhas
                      justifyContent: "space-around",
                      marginTop: 15,
                    }}
                  >
                    {/* Primeira linha de itens */}
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 200,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="text"
                        className="form-control text-center input-text"
                        placeholder="Selecione uma origem"
                        value={nomeOrigem}
                        InputProps={{
                          readOnly: true, // Campo somente leitura
                        }}
                      />
                      <BsSearch
                        color={Cores.bgBotoesNovo}
                        onClick={fetchOrigens}
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                        // width: 200,
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 250,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="text"
                        className="form-control text-center input-text"
                        placeholder="Selecione uma destino"
                        value={nomedestinos}
                        InputProps={{
                          readOnly: true, // Campo somente leitura
                        }}
                      />
                      <BsSearch
                        color={Cores.bgBotoesNovo}
                        onClick={fetchDestinos}
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                        width: 200,
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 220,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="text"
                        className="form-control text-center input-text"
                        placeholder="Selecione uma Perfil"
                        value={nomePerfil}
                        InputProps={{
                          readOnly: true, // Campo somente leitura
                        }}
                      />
                      <BsSearch
                        color={Cores.bgBotoesNovo}
                        onClick={fetchPerfil}
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                        width: 200,
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 200,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="number"
                        className="form-control text-center input-text"
                        placeholder="Digite Qtd Pl"
                        name="qtdpl"
                        value={qtdpl}
                        onChange={changeHandler}
                      />
                    </div>

                    {/* Botão  */}
                    <Button
                      variant="contained"
                      onClick={preencherLista}
                      style={{
                        backgroundColor: Cores.bgBotoesNovo1,
                        color: Cores.clBotoesNovo,
                        marginRight: 100,
                        width: 150,
                      }}
                    >
                      Adicionar
                    </Button>
                  </div>
                  <div
                    style={{
                      marginTop: 100,

                      display: "flex", // Ativa o Flexbox
                      justifyContent: "center", // Alinha horizontalmente
                      alignItems: "center", // Alinha verticalmente
                      height: "100px",
                    }}
                  >
                    <Button
                      style={{
                        height: 30,
                        backgroundColor: Cores.bgBotoesNovo,
                        color: Cores.clBotoesNovo,
                      }}
                      variant="contained"
                      type="submit"
                      onClick={toEditProgramacao}
                    >
                      Salvar Programacao
                    </Button>
                  </div>
                </Paper>
              </div>

              <br></br>
              <br></br>
            </form>
          </Paper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          {/* Modal MOTORISTA */}
          <Modal
            open={showModal}
            // onClose={() => this.setState({ showModal: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",

                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal

                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Motoristas</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar placa"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    //    onChange={(e) =>                        this.setState({ filtramotorista: e.target.value })                     }
                  />
                  <ul>
                    {names
                      .filter((motorista) =>
                        motorista.nome
                          .toLowerCase()
                          .includes((filtramotorista || "").toLowerCase())
                      )
                      .map((motorista) => (
                        <li
                          key={motorista.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectMotorista(motorista.id, motorista.nome)
                          }
                        >
                          {motorista.nome} - {motorista.cpf}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={FecharModalMotorista}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>
          {/* Modal Veiculo trator */}
          <Modal
            open={modalVeiculoAberto}
            //  onClose={() => this.setState({ modalVeiculoAberto: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",

                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal

                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Veiculos</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar placa"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    //   onChange={(e) => this.setState({ filtroPlaca: e.target.value })                     }
                  />
                  <ul>
                    {veiculotrator
                      .filter((veiculo) =>
                        veiculo.placa
                          .toLowerCase()
                          .includes((filtroPlaca || "").toLowerCase())
                      )
                      .map((placa) => (
                        <li
                          key={placa.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectVeiculotrator(placa.id, placa.placa)
                          }
                        >
                          Placa :{placa.placa} - Numero frota :{placa.nfrota}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={FecharModalVeiculotrator}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>
          {/* Modal Veiculo unidade carga */}
          <Modal
            open={showmodalunidadecarga}
            //  onClose={() => this.setState({ showmodalunidadecarga: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal

                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Veiculos</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar placa"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    //    onChange={(e) => this.setState({ filtroUnidadecarga: e.target.value })                      }
                  />
                  <ul>
                    {unidadeCarga
                      .filter((veiculo) =>
                        veiculo.placa
                          .toLowerCase()
                          .includes((filtroPlaca || "").toLowerCase())
                      )
                      .map((placa) => (
                        <li
                          key={placa.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectunidadecarga(placa.id, placa.placa)
                          }
                        >
                          Placa :{placa.placa} - Numero frota :{placa.nfrota}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={FecharModalunidadecarga}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>

          {/* Modal   ORIGEM */}
          <Modal
            open={showModalOrigem}
            //  onClose={() => this.setState({ showModalOrigem: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal
                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Origens</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar origem"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    onChange={handleFiltroOrigemChange}
                  />
                  <ul>
                    {origem
                      .filter((item) =>
                        item.descricao
                          .toLowerCase()
                          .includes((filtroOrigem || "").toLowerCase())
                      )
                      .map((item) => (
                        <li
                          key={item.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectOrigem(item.id, item.descricao)
                          }
                        >
                          Descricao :{item.descricao} - Razao:{item.razao}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    //  onClick={() => this.setState({ showModalOrigem: false })}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>
          {/* Modal   DESTINOS */}
          <Modal
            open={showModalDestinos}
            //  onClose={() => this.setState({ showModalDestinos: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal
                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Destinos</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar Destinos"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    onChange={handlefiltroDestinosChange}
                  />
                  <ul>
                    {destinos
                      .filter((item) =>
                        item.razao
                          .toLowerCase()
                          .includes((filtroDestinos || "").toLowerCase())
                      )
                      .map((item) => (
                        <li
                          key={item.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectDestinos(item.id, item.razao)
                          }
                        >
                          Lojas :{item.lojas} - Razao:{item.razao}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={() => this.setState({ showModalDestinos: false })}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>

          {/* Modal   PERFIL */}
          <Modal
            open={showModalPerfil}
            onClose={() => this.setState({ showModalPerfil: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal
                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de perfil</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar Destinos"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    onChange={handlefiltroPerfilChange}
                  />
                  <ul>
                    {perfil
                      .filter((item) =>
                        item.perfil
                          .toLowerCase()
                          .includes((filtroPerfil || "").toLowerCase())
                      )
                      .map((item) => (
                        <li
                          key={item.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectPerfil(item.id, item.perfil)
                          }
                        >
                          Perfil :{item.perfil}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    //  onClick={() => this.setState({ showModalPerfil: false })}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>
        </div>
      </div>
    </body>
  );
}
