import React, { useState, useEffect } from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import {
  getDestinoid,
  putDest,
  deleteGrupooperacoesdestino,
} from "../service/CRUD_Service";

import Card from "@material-ui/core/Card";

import Typography from "@material-ui/core/Typography";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import api from "../api";

export default function DestinoEdit({ history }) {
  //class updateveiculo extends Component{
  const { id } = history.location.state;
  const [dest, setDest] = useState({});
  const [destErro, setDestErro] = useState("");
  const [codigo, setCodigo] = useState("");
  const [razao, setRazao] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [lojas, setlojas] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [cep, setCep] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [bairro, setBairro] = useState("");
  const [uf, setUf] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [recebimentohrinicial, setRecebimentohrinicial] = useState("");
  const [recebimentohrfinal, setRecebimentohrfinal] = useState("");
  const [tipoveiculo, setTipoveiculo] = useState("");
  const [comprimentoveiculo, setComprimentoveiculo] = useState("");
  const [cerca, setCerca] = useState("");

  const [btntoperacao, setBtnoperacao] = useState();
  const [tipoperacao, setTipoperacao] = useState();
  const [tpoperacoes, setTpoperacoes] = useState();

  //tipo de operacao
  useEffect(() => {
    api
      .get("/operacoes")
      .then((res) => {
        setTipoperacao(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getDestinoid(id)
      .then((res) => {
        setDest(res);
        setCodigo(res.codigo);
        setRazao(res.razao);
        setCnpj(res.cnpj);
        setlojas(res.lojas);
        setRua(res.rua);
        setNumero(res.numero);
        setCep(res.cep);
        setMunicipio(res.municipio);
        setBairro(res.bairro);
        setUf(res.uf);
        setLongitude(res.longitude);
        setLatitude(res.latitude);
        setRecebimentohrinicial(res.recebimentohrinicial);
        setRecebimentohrfinal(res.recebimentohrfinal);
        setTipoveiculo(res.tipoveiculo);
        setComprimentoveiculo(res.comprimentoveiculo);
        setCerca(res.cerca);
        setTpoperacoes(res.Cad_operacoes);
      })
      .catch((err) => setDestErro(err));
  }, [id]);

  //codigo
  const onChangencodigo = (event) => {
    setCodigo(event.target.value);
  };
  // razao
  const onChangerazao = (event) => {
    setRazao(event.target.value);
  };
  // cnpj
  const onChangecnpj = (event) => {
    setCnpj(event.target.value);
  };
  //descricao
  const onChangedescricao = (event) => {
    setlojas(event.target.value);
  };
  //rua
  const onChangerua = (event) => {
    setRua(event.target.value);
  };
  //numero
  const onChangenumero = (event) => {
    setNumero(event.target.value);
  };
  //cep
  const onChangecep = (event) => {
    setCep(event.target.value);
  };
  //municipio
  const onChangemunicipio = (event) => {
    setMunicipio(event.target.value);
  };
  //bairro
  const onChangebairro = (event) => {
    setBairro(event.target.value);
  };
  //uf
  const onChangeuf = (event) => {
    setUf(event.target.value);
  };
  //longitude
  const onChangelongitude = (event) => {
    setLongitude(event.target.value);
  };
  //latitude
  const onChangelatitude = (event) => {
    setLatitude(event.target.value);
  };
  //recebimentohrinicial
  const onChangerecebimentohrinicial = (event) => {
    setRecebimentohrinicial(event.target.value);
  };
  //recebimentohrfinal
  const onChangerecebimentohrfinal = (event) => {
    setRecebimentohrfinal(event.target.value);
  };
  //Tipo de Veiculo
  const onChangetipoveiculo = (event) => {
    setTipoveiculo(event.target.value);
  };
  //Comprimento
  const onChangecomprimentoveiculo = (event) => {
    setComprimentoveiculo(event.target.value);
  };
  //Comprimento
  const onChangecerca = (event) => {
    setCerca(event.target.value);
  };

  // //tipo operacao
  const onChangeoperacao = (event) => {
    setBtnoperacao(event.target.value);
  };

  const onDeleteAgrupoperacao = (id) => {
    deleteGrupooperacoesdestino(id)
      .then(() => {
        refreshPage();
      })
      .catch((err) => console.log(err));
  };
  ///atualiza a pagina
  const refreshPage = () => {
    window.location.reload();
  };

  //salvar operacao
  const Salvaroperacao = () => {
    let filtraroperacao = tpoperacoes.filter((x) =>
      btntoperacao.includes(x.id)
    );

    if (filtraroperacao.length > 0) {
      return alert(
        "Atencao !!!, nao pode vincular operacao ja existente no usuario"
      );
    } else {
      api
        .post("agrupaoperacoesdestino", {
          fkdestinos: id,
          fkoperacoes: btntoperacao,
        })
        .then((error) => {
          refreshPage();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const toEdit = (event) => {
    event.preventDefault();
    dest.codigo = codigo;
    dest.razao = razao;
    dest.cnpj = cnpj;
    dest.lojas = lojas;
    dest.rua = rua;
    dest.numero = numero;
    dest.cep = cep;
    dest.municipio = municipio;
    dest.bairro = bairro;
    dest.uf = uf;
    dest.longitude = longitude;
    dest.latitude = latitude;
    dest.recebimentohrinicial = recebimentohrinicial;
    dest.recebimentohrfinal = recebimentohrfinal;
    dest.tipoveiculo = tipoveiculo;
    dest.comprimentoveiculo = comprimentoveiculo;
    dest.cerca = cerca;
    putDest(id, dest)
      .then(() => history.push("/ListarDestino"))
      .catch((err) => setDestErro(err));
  };

  return (
    <div className="Users">
      <Paper
        variant="outlined"
        elevation={4}
        style={{
          backgroundColor: "#FFFF",
          marginLeft: 100,
          marginRight: 100,
          marginTop: 50,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#abb8c3",
          borderRadius: 10,
          minWidth: 410,
        }}
      >
        <form>
          <div
            style={{
              display: "flex",
              backgroundColor: "#191E4D",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              height: 50,
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                color: "#cccccc",
              }}
            >
              Editar Destinos
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                minWidth: 100,
                maxWidth: 100,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Codigo"
              placeholder="00"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="codigo"
              value={codigo}
              onChange={onChangencodigo}
            />

            <TextField
              style={{
                marginTop: 25,
                flex: 1,
                minWidth: 350,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Razao Social"
              placeholder="******"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="razao"
              value={razao}
              onChange={onChangerazao}
            />

            <TextField
              style={{
                marginTop: 25,
                flex: 1,
                maxWidth: 390,
                minWidth: 350,
              }}
              required
              id="standard-required"
              label="CNPJ"
              placeholder="00.000.000/0001-00"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="cnpj"
              value={cnpj}
              onChange={onChangecnpj}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Rua"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="rua"
              value={rua}
              onChange={onChangerua}
            />
            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                maxWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Nº"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="numero"
              value={numero}
              onChange={onChangenumero}
            />

            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                maxWidth: 390,
                flex: 1,
              }}
              required
              id="standard-required"
              label="Bairro"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="bairro"
              value={bairro}
              onChange={onChangebairro}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                maxWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="CEP"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="cep"
              value={cep}
              onChange={onChangecep}
            />
            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Municipio"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="municipio"
              value={municipio}
              onChange={onChangemunicipio}
            />
            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                maxWidth: 390,
                flex: 1,
              }}
              required
              id="standard-required"
              label="UF"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="uf"
              value={uf}
              onChange={onChangeuf}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                minWidth: 320,
                maxWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Loja"
              placeholder="00"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="lojas"
              value={lojas}
              onChange={onChangedescricao}
            />

            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Longitude"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="longitude"
              value={longitude}
              onChange={onChangelongitude}
            />

            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                maxWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              required
              id="standard-required"
              label="Latitude"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="latitude"
              value={latitude}
              onChange={onChangelatitude}
            />
            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                maxWidth: 390,
                flex: 1,
              }}
              required
              id="standard-required"
              label="Cerca"
              placeholder="xxx"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="latitude"
              value={cerca}
              onChange={onChangecerca}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              //  alignItems: 'center',
              //  justifyContent: 'center',
              justifyContent: "space-evenly",
              paddingLeft: 50,
              paddingRight: 50,
              marginTop: 25,
            }}
          >
            {/*CARD DE RESTRICOES DE VEICULOS  */}
            <Card
              style={{
                // display: "flex",
                //maxWidth: 390,
                flex: 1,
                marginRight: 30,
                minWidth: 350,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cccccc",
                  marginBottom: 15,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                  }}
                >
                  Restrições
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  //  alignItems: 'center',
                  //  justifyContent: 'center',
                  // justifyContent: "space-evenly",
                  marginLeft: 20,
                  marginTop: 25,
                  marginBottom: 30,
                }}
              >
                <TextField
                  style={{
                    flex: 1,
                    maxWidth: 250,
                    minWidth: 100,
                    marginRight: 100,
                  }}
                  required
                  id="standard-required"
                  label="Hora Inicial recebimento"
                  placeholder="00:00"
                  multiline
                  variant="filled"
                  margin="dense"
                  type="text"
                  name="recebimentohrinicial"
                  value={recebimentohrinicial}
                  onChange={onChangerecebimentohrinicial}
                />
                <TextField
                  style={{
                    flex: 1,
                    maxWidth: 250,
                    minWidth: 100,
                  }}
                  required
                  id="standard-required"
                  label="Hora Final recebimento"
                  placeholder="xxx"
                  multiline
                  variant="filled"
                  margin="dense"
                  type="number"
                  name="recebimentohrfinal"
                  value={recebimentohrfinal}
                  onChange={onChangerecebimentohrfinal}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  //  alignItems: 'center',
                  //  justifyContent: 'center',
                  // justifyContent: "space-evenly",
                  marginLeft: 20,
                  marginTop: 25,
                  marginBottom: 30,
                }}
              >
                <FormControl
                  variant="filled"
                  style={{
                    flex: 1,
                    maxWidth: 250,
                    minWidth: 100,
                    marginRight: 100,
                  }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione tipo veiculo
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    margin="dense"
                    label="Tipo Veiculo"
                    name="tipoveiculo"
                    value={tipoveiculo}
                    onChange={onChangetipoveiculo}
                  >
                    <MenuItem value="">
                      <em>Tipo Veiculo</em>
                    </MenuItem>

                    <MenuItem className="Truck" value={"CT"}>
                      CT
                    </MenuItem>
                    <MenuItem className="semi-reboque-termico" value={"SRT"}>
                      SRT
                    </MenuItem>
                    <MenuItem className="semi-reboque" value={"SR"}>
                      SR
                    </MenuItem>
                    <MenuItem
                      className="semi-reboque-frigorifica"
                      value={"SRF"}
                    >
                      SRF
                    </MenuItem>
                    <MenuItem className="Caminhao-trator-cavalo" value={"CTC"}>
                      CTC
                    </MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  style={{
                    flex: 1,
                    maxWidth: 250,
                    minWidth: 100,
                    marginRight: 100,
                  }}
                  required
                  id="standard-required"
                  label="Comprimento veiculo"
                  placeholder="xxx"
                  multiline
                  variant="filled"
                  margin="dense"
                  type="number"
                  name="comprimentoveiculo"
                  value={comprimentoveiculo}
                  onChange={onChangecomprimentoveiculo}
                />
              </div>
            </Card>

            {/*CARD PARA DEFINIR A OPERACAO QUE ELE PERTENCE    */}
            <Card
              style={{
                // display: "flex",
                //maxWidth: 390,
                flex: 1,
                minWidth: 350,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cccccc",
                  marginBottom: 15,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                  }}
                >
                  Adicionar operacao
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 500,
                    marginTop: 10,
                    marginBottom: 30,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 10,
                      marginLeft: 5,
                      fontSize: 14,
                    }}
                  >
                    Mensagem :
                  </Typography>
                  <FormControl
                    id="standard-basic"
                    style={{ maxWidth: 225, minWidth: 200, flex: 1 }}
                  >
                    <Select
                      native
                      value={btntoperacao}
                      onChange={onChangeoperacao}
                      label="operacao"
                      inputProps={{
                        name: "operacao",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {tipoperacao?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tpoperacao}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    style={{
                      marginTop: 5,
                      color: "#2d8659",
                      height: 40,
                      width: 40,
                      borderRadius: 40 / 2,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                    onClick={Salvaroperacao}
                  >
                    <AddCircleIcon
                      style={{
                        fontSize: 30,
                        color: "#2d8659",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //backgroundColor: 'red',
                  marginBottom: 15,
                }}
              >
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    bgcolor: "background.paper",
                  }}
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#abb8c3",
                    borderRadius: 5,
                    //backgroundColor: 'red',
                  }}
                >
                  {tpoperacoes?.map((value) => (
                    <ListItem
                      key={value}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() =>
                            onDeleteAgrupoperacao(
                              value.agrupaoperacoesdestinos.id
                            )
                          }
                        >
                          <DeleteIcon
                            style={{
                              color: "#862d2d",
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        <Typography>{value.id}</Typography>
                      </ListItemText>

                      <ListItemText>
                        <Typography>{value.tpoperacao}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginTop: 5,
                marginBottom: 30,
                marginTop: 50,
              }}
              variant="contained"
              color="primary"
              onClick={toEdit}
            >
              Salvar
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
