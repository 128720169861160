import React, { useEffect, useState, version } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Cores } from "../style/Color";
import Card from "@material-ui/core/Card";
import apistr from "../api"; //api str
import Popover from "@mui/material/Popover";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FilterListIcon from "@material-ui/icons/FilterList";
import Modal from "@mui/material/Modal";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Motorista from "@material-ui/icons/Person";

import CommentIcon from "@material-ui/icons/Comment";

import { logout, getOperacao } from "../service/authService";
import moment from "moment";
import "moment-duration-format";

export default function CardGrid({ data }) {
  const [entregasentregue, SetEntregasentregue] = useState();
  const [cadOperacoes, setCadOperacoes] = useState([]);
  const [operacaoSelecionada, setOperacaoSelecionada] = useState("");
  const [entregaspendente, SetEntregaspendente] = useState();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorLegenda, setAnchorLegenda] = React.useState(null);
  const [chunkSize, setChunkSize] = useState(12); // Valor inicial padrão
  const groupedData = [];
  const [selectedCard, setSelectedCard] = useState(null); // Card selecionado
  const [isModalOpen, setIsModalOpen] = useState(false); // Controle do modal

  console.log("seletccard", selectedCard);
  const handleCardClick = (item) => {
    setSelectedCard(item);
    setIsModalOpen(true); // Abre o modal ao clicar no card
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null); // Fecha o modal e limpa o card selecionado
  };
  // Divide os dados com base no chunkSize
  for (let i = 0; i < data?.length; i += chunkSize) {
    groupedData.push(data.slice(i, i + chunkSize));
  }
  const classes = useStyles();

  useEffect(() => {
    const operacoes = getOperacao();
    if (operacoes) {
      setCadOperacoes(operacoes);

      // Encontra a operação com o nome "Koch - CD"
      const kochCdOperacao = operacoes.find((op) => op.id === 3);
      if (kochCdOperacao) {
        // Define o ID da operação selecionada como o ID da operação "Koch - CD"
        setOperacaoSelecionada(kochCdOperacao.id);
      } else {
        const operacaoPadrao = operacoes[0];
        setOperacaoSelecionada(operacaoPadrao.id);
      }
    }
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openlegenda = Boolean(anchorLegenda);
  const idleganda = openlegenda ? "simple-popover" : undefined;

  const handleClickleganda = (event) => {
    setAnchorLegenda(event.currentTarget);
  };
  const handleCloseleganda = () => {
    setAnchorLegenda(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const fetchDataEntregue = () => {
      apistr
        .get("/painellojasentregasentregue")
        .then((res) => {
          let dados = res.data;
          // Obtém a data do dia atual no formato "YYYY-MM-DD"
          const hoje = moment().format("YYYY-MM-DD");

          // Filtra apenas os dados com `dataentregapadrao` correspondente à data de hoje
          const entregasHoje = dados.filter(
            (entrega) => entrega.dataentregapadrao === hoje
          );

          SetEntregasentregue(entregasHoje);
        })
        .catch(() => alert("erro ao carregar"));
    };

    // Chamar a função imediatamente na primeira vez
    fetchDataEntregue();

    // Configurar o intervalo para chamar a função a cada 5 minutos (300000 ms)
    const interval = setInterval(fetchDataEntregue, 300000);

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, []);

  const handleOperacaoChange = (event) => {
    setOperacaoSelecionada(event.target.value);
  };
  var ConverteOperacaotipo = Number(operacaoSelecionada);

  useEffect(() => {
    const fetchData = () => {
      apistr
        .get("/painellojasentregaspendente")
        .then((res) => {
          let dados = res.data;
          SetEntregaspendente(dados);
        })
        .catch(() => alert("erro ao carregar"));
    };

    // Chamar a função imediatamente na primeira vez
    fetchData();

    // Configurar o intervalo para chamar a função a cada 5 minutos (300000 ms)
    const interval = setInterval(fetchData, 300000);

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, []);
  const tonsDeVerde = [Cores.Verde3, Cores.Verde2, Cores.Verde1, Cores.Verde];

  function obterCorPorEntregas(totalEntregas) {
    if (totalEntregas === 0) return tonsDeVerde[0];
    if (totalEntregas === 1) return tonsDeVerde[1];
    if (totalEntregas === 2) return tonsDeVerde[2];
    return tonsDeVerde[3]; // Para 3 ou mais entregas
  }

  function filtrarPorOperacao(array, operacaoSelecionada) {
    const ConverteOperacaotipo = Number(operacaoSelecionada);

    return array?.filter((item) =>
      item.fkorigem?.Cad_operacoes?.some((op) => op.id === ConverteOperacaotipo)
    );
  }

  return (
    <div>
      {/* Select para definir o chunkSized */}
      <div
        style={{
          marginBottom: "20px",
          marginTop: 15,
          display: "flex",
          justifyContent: "flex-end", // Alinha os itens à direita
          alignItems: "center", // Centraliza verticalmente os itens dentro da div
        }}
      >
        <Typography className={classes.txtcardsporlinha}>
          Cards por linha:
        </Typography>
        <select
          id="chunk-size-select"
          value={chunkSize}
          onChange={(e) => setChunkSize(Number(e.target.value))} // Atualiza chunkSize
          style={{
            padding: "1px",
            borderRadius: "3px",
            border: "1px solid #ccc",
            backgroundColor: Cores.Sinza2,
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={12}>12</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>

        <Button
          variant="contained"
          color="success"
          style={{ marginLeft: 10, height: 20 }}
          onClick={handleClick}
          startIcon={<FilterListIcon />}
        >
          Filtro Operacao
        </Button>
        <Button
          variant="contained"
          color="success"
          style={{ marginLeft: 10, height: 20 }}
          onClick={handleClickleganda}
          startIcon={<FilterListIcon />}
        >
          Legenda
        </Button>

        <Popover
          id={idleganda}
          open={openlegenda}
          anchorEl={anchorLegenda}
          onClose={handleCloseleganda}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: 300, // largura do Popover
              maxHeight: 700, // altura máxima do Popover
            },
          }}
        >
          <Typography
            style={{
              backgroundColor: Cores.Azul1,
              color: Cores.Branco1,
              padding: 5,
            }}
          >
            Card Azul : Nenhuma entrega para a loja no dia
          </Typography>
          <Typography
            style={{
              backgroundColor: Cores.Amarelo1,
              color: Cores.Branco1,
              padding: 5,
            }}
          >
            Card Amarelo : Existe entregas pendentes para a loja
          </Typography>
          <Typography
            style={{
              backgroundColor: Cores.Verde,
              color: Cores.Branco1,
              padding: 5,
            }}
          >
            Card Verde : todas entregas realizadas, nenhuma pendente
          </Typography>
          <Typography>
            <Motorista className={classes.iconMotorista} />
            Icone em vermelho quer dizer que nao foi definido motorista para
            alguma entrega da loja
          </Typography>
          <Typography>
            <Motorista className={classes.iconMotoristadestinado} />
            Icone em verde quer dizer que todas as entregas pendentes tem
            motorista
          </Typography>
        </Popover>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: 300, // largura do Popover
              maxHeight: 700, // altura máxima do Popover
              paddingLeft: 3,
            },
          }}
        >
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Operacoes</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={operacaoSelecionada}
              onChange={handleOperacaoChange}
              name="radio-buttons-group"
            >
              {cadOperacoes.map((op, index) => (
                <FormControlLabel
                  key={index}
                  value={op.id}
                  control={<Radio />}
                  label={op.tpoperacao}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Popover>
      </div>
      {groupedData.map((group, index) => (
        <div key={index} style={{ display: "flex", flexWrap: "wrap" }}>
          {group.map((item) => {
            const filtrooperacaoentregue = filtrarPorOperacao(
              entregasentregue,
              operacaoSelecionada
            );

            const filtrooperacaoPendente = filtrarPorOperacao(
              entregaspendente,
              operacaoSelecionada
            );
            // Filtra as entregas para o destino atual
            const totalEntregas = filtrooperacaoentregue?.filter(
              (entrega) => entrega.fkdestino.lojas === item.lojas
            ).length;

            const totalEntregaspendente = filtrooperacaoPendente?.filter(
              (entrega) => entrega.fkdestino.lojas === item.lojas
            ).length;

            const FiltroEntregasdetalhependente =
              filtrooperacaoentregue?.filter(
                (entrega) => entrega.fkdestino.lojas === item.lojas
              );
            const FiltroEntregasdetalheentregue =
              filtrooperacaoPendente?.filter(
                (entrega) => entrega.fkdestino.lojas === item.lojas
              );

            //  const agrupa = [totalEntregas, totalEntregaspendente];
            const entregasAgrupadas = [
              ...(FiltroEntregasdetalhependente || []),
              ...(FiltroEntregasdetalheentregue || []),
            ];

            const corDeFundo = obterCorPorEntregas(
              totalEntregas + totalEntregaspendente
            );

            // Filtra as entregas entregues e pendentes para a loja específica
            const entregasLoja = [
              ...(filtrooperacaoPendente?.filter(
                (entrega) => entrega.fkdestino.lojas === item.lojas
              ) || []),
              ...(filtrooperacaoentregue?.filter(
                (entrega) => entrega.fkdestino.lojas === item.lojas
              ) || []),
            ];

            // Verifica se alguma entrega dessa loja tem o motorista "Padrao"
            const motoristaPadrao = entregasLoja.some(
              (entrega) => entrega.cviagem?.fkmotorista?.nome === "Padrao"
            );

            return (
              <Card
                onClick={() => handleCardClick(entregasAgrupadas)}
                key={item.id}
                elevation={12}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "1px",
                  margin: "5px",
                  width: "130px",
                  height: "140px",
                  textAlign: "center",
                  backgroundColor: totalEntregaspendente
                    ? Cores.Amarelo1
                    : totalEntregas
                    ? Cores.Verde
                    : Cores.Azul1,

                  borderColor: Cores.Azul2,
                  transition: "transform 0.3s ease-in-out",
                  transform:
                    hoveredCard === item.id ? "scale(1.1)" : "scale(1)", // Aplica o zoom
                }}
                onMouseEnter={() => setHoveredCard(item.id)} // Marca o card como ativo
                onMouseLeave={() => setHoveredCard(null)} // Remove o estado ao sair
              >
                <div>
                  <Typography className={classes.txttitulocard}>
                    {item.lojas}
                  </Typography>
                  <Typography className={classes.txtsbutitulo}>Loja</Typography>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    marginRight: 5,
                    marginLeft: 5,
                    justifyItems: "flex-start",
                  }}
                >
                  <Typography className={classes.txtCardstatus}>
                    Entregue : {totalEntregas}
                  </Typography>
                  <Typography className={classes.txtCardstatus}>
                    Pendente : {totalEntregaspendente}{" "}
                    {entregasAgrupadas.length > 0 && // Só verifica o motorista se houver entregas
                      (motoristaPadrao ? (
                        <Motorista className={classes.iconMotorista} />
                      ) : (
                        <Motorista className={classes.iconMotoristadestinado} />
                      ))}
                  </Typography>
                </div>
              </Card>
            );
          })}
        </div>
      ))}
      {/* Modal para exibir os detalhes */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "fixed", // Centraliza a posição
            top: "50%", // Centraliza verticalmente
            left: "50%", // Centraliza horizontalmente
            transform: "translate(-50%, -50%)", // Ajuste para realmente centralizar
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2, // Diminui o padding para telas pequenas
            zIndex: 1300,
            width: "90%", // Ajusta a largura para 90% da tela
            height: "50%",

            overflowY: "auto", // Permite rolagem se o conteúdo for maior que a tela
          }}
        >
          {selectedCard && (
            <div>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Id Viagem</TableCell>
                      <TableCell align="center">Origem</TableCell>
                      <TableCell align="center">Destino</TableCell>
                      <TableCell align="center">Cidade</TableCell>
                      <TableCell align="center">Perfil</TableCell>
                      <TableCell align="center">Qtd Pallet</TableCell>
                      <TableCell align="center">Unidade Carga</TableCell>
                      <TableCell align="center">Placa Cavalo</TableCell>
                      <TableCell align="center">Motorista</TableCell>
                      <TableCell align="center">Data Carga criada</TableCell>
                      <TableCell align="center">Data Entrega</TableCell>
                      <TableCell align="center">Status Entrega</TableCell>
                      <TableCell align="center">Ocorrencias</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedCard?.map((item) => (
                      <TableRow>
                        <TableCell align="center">{item.viagem}</TableCell>
                        <TableCell component="th" align="center">
                          {item.fkorigem.descricao}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {item.fkdestino.lojas}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {item.fkdestino.municipio}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {item.perfil}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {item.qtdpallet}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {item.cviagem.fkveiculo.nfrota}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {item.cviagem.fkveiculotrator.placa}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {item.cviagem.fkmotorista.nome}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {(() => {
                            const format1 = "DD/MM/YYYY HH:mm:ss";
                            let horseteste = new Date(item.createdAt);

                            let formatarentradacd = moment
                              .utc(horseteste)
                              .format(format1);
                            return formatarentradacd;
                          })()}
                        </TableCell>

                        <TableCell component="th" align="center">
                          {(() => {
                            const format1 = "DD/MM/YYYY HH:mm:ss";
                            let horseteste = new Date(item.dataentrega);

                            let formatarentradacd = moment
                              .utc(horseteste)
                              .format(format1);

                            if (item.dataentrega) {
                              return formatarentradacd;
                            } else {
                              return "";
                            }
                          })()}
                        </TableCell>
                        {/* Verifica se o veículo está scom carga */}
                        <TableCell align="center">
                          {(() => {
                            if (item.statusentrega == "Pendente") {
                              return (
                                <Typography
                                  style={{
                                    backgroundColor: Cores.Vermelho1,
                                    color: Cores.Branco1,
                                    borderRadius: 5,
                                    padding: 2,
                                  }}
                                >
                                  {item.statusentrega}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  style={{
                                    backgroundColor: Cores.Verde1,
                                    color: Cores.Branco1,
                                    borderRadius: 5,
                                    padding: 2,
                                  }}
                                >
                                  {item.statusentrega}
                                </Typography>
                              );
                            }
                          })()}
                        </TableCell>
                        <TableCell component="th" align="center">
                          <CommentIcon style={{ color: Cores.Verde3 }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  txtCardstatus: {
    color: Cores.Branco1,
    fontSize: 13,
  },
  txttitulocard: {
    color: Cores.Branco1,
    fontSize: 38,
    fontWeight: "bold",
    marginTop: 8,
  },
  txtsbutitulo: {
    color: Cores.Branco1,
    fontSize: 13,
    fontWeight: "bold",
    marginTop: -18,
  },
  txtcardsporlinha: {
    fontSize: 14,
    marginRight: 3,
  },
  iconMotorista: {
    marginTop: -5,
    color: Cores.Vermelho2,
  },
  iconMotoristadestinado: {
    marginTop: -5,
    color: Cores.Verde3,
  },
}));
