//IMPORTACOES
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./service/authService";

//VISUALIZAR
import Dashaboard from "./paginas/Dashaboard";
import HomePage from "./paginas/Home";
import Paglogin from "./paginas/Login";
import DashaboardCarga from "./paginas/Dashaboard_carga";
import manifesto from "./paginas/Manifesto";
import relatorios from "./paginas/relatorios";

//CADASTRAR
import Cadusuario from "./paginas/Cad_usuario";
import CadOperacoes from "./paginas/Cad_operacoes";
import CadEntrega from "./paginas/Cad_entrega";
import Mapa from "./paginas/Mapa";
import Painel_lojas from "./paginas/Painel_lojas";
import CadVeiculo from "./paginas/cad_veiculo";
import CadMotorista from "./paginas/Cad_motorista";
import CadOrigem from "./paginas/Cad_origem";
import CadDestino from "./paginas/Cad_destino";
import CadMensagem from "./paginas/Cad_mensagem";
import CadRotas from "./paginas/Cad_rotas";
import ProgramacaoCarga from "./paginas/ProgramacaoCarga";

///LISTAR
import ListarUsers from "./paginas/listar_usuarios/listar_usuarios";
import ListarVeiculo from "./paginas/listar_veiculo/listar_veiculo";
import ListarMotorista from "./paginas/listar_motoristas/listar_motorista";
import ListarOrigem from "./paginas/listar_origem/listar_origem";
import ListarDestino from "./paginas/listar_destinos/listar_destino";
import ListarMensagem from "./paginas/MensagemAutomatica/listar_Mensagem";
import ListarRotas from "./paginas/Lista_de_Rotas/Lista_rotas";
import ListarOperacoes from "./paginas/listar_operacoes/listar_operacoes";
import Lista_statuscd from "./paginas/Lista_StatusveiculoCD/Lista_statuscd";
import Lista_statusLojas from "./paginas/Lista_StatusEntregas/Lista_statusEntregas";
import Lista_programacaoCarga from "./paginas/listar_programacaocarga/listar_programacaocarga";

//PAGINAS DE EDITAR
import OperacoesEdit from ".//componentes/Form_Operacoes_update";
import UsersEdit from ".//componentes/Form_usuarios_update";
import VelEdit from ".//componentes/Form_veiculos_update";
import MotEdit from ".//componentes/Form_motorista_update";
import OrigemEdit from ".//componentes/Form_origem_update";
import DestinoEdit from ".//componentes/Form_destino_update";
import MensagemEdit from ".//componentes/Form_mensagem_update";
import StatuscdEdit from ".//componentes/Form_Statuscd_update";
import StatusLojaEdit from ".//componentes/Form_StatusLoja_update";
import ProgramacaoCargaEdit from ".//componentes/Form_ProgramacaoCarga_update";

//PAGINAS KOCH
//import ListCargasKoch from './'
import ListaCargasKoch from "./paginas/Koch/ListaCargaKoch";
import Timelinekoch from ".//componentes/Timelinekoch";

//PAGINAS PROCESSAMENTO
import PainelProcessamento from "./paginas/Painel_Processamento";
import PowerBiCargas from "./paginas/PowerBiCargas";

//PAGINAS DE EXPORTACOES
import Exportacaostatus from "./paginas/Exportacaostatus/Listastatus";
import Exportacaoentregas from "./paginas/Exportacaoentregas/Listasentregas";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Paglogin} />
        <PrivateRoute path="/Home" exact component={HomePage} />

        {/* PAGINAS DE VISUALIZAÇÕES*/}
        <PrivateRoute path="/DashaboardCarga" component={DashaboardCarga} />
        <PrivateRoute path="/relatorios" component={relatorios} />
        <PrivateRoute path="/manifesto" component={manifesto} />
        <PrivateRoute path="/Dashaboard" component={Dashaboard} />

        {/* PAGINAS DE CADASTROS*/}
        <PrivateRoute path="/CadEntrega" component={CadEntrega} />
        <PrivateRoute path="/Mapa" component={Mapa} />
        <PrivateRoute path="/PainelLojas" component={Painel_lojas} />
        <PrivateRoute path="/CadVeiculo" component={CadVeiculo} />
        <PrivateRoute path="/CadMotorista" component={CadMotorista} />
        <PrivateRoute path="/CadOrigem" component={CadOrigem} />
        <PrivateRoute path="/CadDestino" component={CadDestino} />
        <PrivateRoute path="/Cadusuario" component={Cadusuario} />
        <PrivateRoute path="/Cadmensagem" component={CadMensagem} />
        <PrivateRoute path="/CadRotas" component={CadRotas} />
        <PrivateRoute path="/CadOperacoes" component={CadOperacoes} />
        <PrivateRoute path="/ProgramacaoCarga" component={ProgramacaoCarga} />

        {/* PAGINAS DE EDITAR DADOS*/}
        <PrivateRoute path="/UsersEdit" component={UsersEdit} />
        <PrivateRoute path="/OperacoesEdit" component={OperacoesEdit} />
        <PrivateRoute path="/OrigemEdit" component={OrigemEdit} />
        <PrivateRoute path="/VelEdit" component={VelEdit} />
        <PrivateRoute path="/MotEdit" component={MotEdit} />
        <PrivateRoute path="/DestinoEdit" component={DestinoEdit} />
        <PrivateRoute path="/MesangemEdit" component={MensagemEdit} />
        <PrivateRoute path="/StatuscdEdit" component={StatuscdEdit} />
        <PrivateRoute path="/StatusLojaEdit" component={StatusLojaEdit} />
        <PrivateRoute
          path="/ProgramacaocargaEdit"
          component={ProgramacaoCargaEdit}
        />

        {/* PAGINAS DE TABELAS*/}
        <PrivateRoute path="/ListarMotorista" component={ListarMotorista} />
        <PrivateRoute path="/ListarVeiculo" component={ListarVeiculo} />
        <PrivateRoute path="/ListarOrigem" component={ListarOrigem} />
        <PrivateRoute path="/ListarDestino" component={ListarDestino} />
        <PrivateRoute path="/ListarUsers" exact component={ListarUsers} />
        <PrivateRoute path="/ListOperacoes" exact component={ListarOperacoes} />
        <PrivateRoute path="/Listastatuscd" exact component={Lista_statuscd} />
        <PrivateRoute
          path="/Listastatusloja"
          exact
          component={Lista_statusLojas}
        />
        <PrivateRoute path="/ListarMensagem" exact component={ListarMensagem} />
        <PrivateRoute path="/ListaRotas" exact component={ListarRotas} />
        <PrivateRoute
          path="/ListaProgramacao"
          exact
          component={Lista_programacaoCarga}
        />

        {/* PAGINAS DE CADASTROS*/}
        <PrivateRoute
          path="/PainelProcessamento"
          exact
          component={PainelProcessamento}
        />
        <PrivateRoute path="/powerbicargas" exact component={PowerBiCargas} />

        {/* PAGINAS DO KOCH*/}
        <PrivateRoute path="/listakoch" exact component={ListaCargasKoch} />

        {/* PAGINAS DO KOCH*/}
        <PrivateRoute path="/timelinekoch" exact component={Timelinekoch} />

        {/* PAGINAS DO EXPORTACOES*/}
        <PrivateRoute
          path="/statusveiculo"
          exact
          component={Exportacaostatus}
        />
        <PrivateRoute
          path="/ExpEntregas"
          exact
          component={Exportacaoentregas}
        />
      </Switch>
    </BrowserRouter>
  );
}
