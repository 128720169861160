import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../App.css";
import { Cores } from "../style/Color";

import { logout, getOperacao } from "../service/authService";

import apistr from "../api"; //api str
import CardGrid from "../componentes/CardLojas";

export default function PainelLojas() {
  const [destinos, SetDestinos] = useState();
  const [cadOperacoes, setCadOperacoes] = useState([]);
  const [operacaoSelecionada, setOperacaoSelecionada] = useState("");

  const classes = useRowStyles();

  useEffect(() => {
    const operacoes = getOperacao();
    if (operacoes) {
      setCadOperacoes(operacoes);

      // Encontra a operação com o nome "Koch - CD"
      const kochCdOperacao = operacoes.find((op) => op.id === 3);
      if (kochCdOperacao) {
        // Define o ID da operação selecionada como o ID da operação "Koch - CD"
        setOperacaoSelecionada(kochCdOperacao.id);
      } else {
        const operacaoPadrao = operacoes[0];
        setOperacaoSelecionada(operacaoPadrao.id);
      }
    }
  }, []);

  const handleOperacaoChange = (event) => {
    setOperacaoSelecionada(event.target.value);
  };
  console.log(operacaoSelecionada);

  //busca destinos api
  useEffect(() => {
    apistr
      .get("/destino")
      .then((res) => {
        let dados = res.data;

        const itens = dados.sort(function (a, b) {
          var c = a.lojas;
          var d = b.lojas;
          return c - d; //data mais antiga para mais nova
        });
        SetDestinos(itens);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  //filtra a operação
  let filterdestino = destinos?.filter((x) =>
    x.Cad_operacoes.some((i) => i.id === operacaoSelecionada)
  );

  return (
    <div style={{ justifyItems: "center" }}>
      <CardGrid data={filterdestino} />
    </div>
  );
}
//styles
const useRowStyles = makeStyles({
  root: {
    //backgroundColor: 'red',
    height: 320,
    width: 650,
  },
  head: {
    backgroundColor: "#FFFFFF",
  },
  txtheard: {
    fontWeight: "bold",
  },
  titulopag: {
    fontSize: 37,
    color: Cores.Sinza1,
  },
});
