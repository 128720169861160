import React, { Component } from "react";
import api from "../api";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TextField from "@material-ui/core/TextField";

class mesagem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tipo: "",
      descricaomensagem: "",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    api
      .post("/tipomensagem", this.state)
      .then((response) => {
        console.log(response);
        alert("MENSAGEM SALVA COM SUCESSO!!! ");
        window.location = "/ListarMensagem";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { tipo, descricaomensagem } = this.state;

    return (
      <div>
        <Paper
          square
          elevation={1}
          variant="outlined"
          style={{
            backgroundColor: "#FFFF",
            // height: 500,
            //width: 450,
            // marginLeft: 230,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#abb8c3",
                borderRadius: 10,
                minWidth: 660,
                marginTop: 10,
                marginBottom: 20,
              }}
              onSubmit={this.submitHandler}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <FormControl
                  id="standard-basic"
                  style={{
                    marginLeft: 40,
                    marginTop: 30,
                    width: 225,
                  }}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Tipo Mensagem
                  </InputLabel>
                  <Select
                    native
                    value={tipo}
                    onChange={this.changeHandler}
                    label="Tipo"
                    inputProps={{
                      name: "tipo",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={1}>Saida de Loja</option>
                    <option value={2}>Entrega Finalizada</option>
                    <option value={3}>Carga Gerada</option>
                    <option value={4}>Documento Gerado</option>
                    <option value={5}>Entrega Gerada</option>
                    <option value={6}>Tempo de Loja</option>
                    <option value={7}>Saida CD</option>
                    <option value={8}>Saida CD sem documento</option>
                    <option value={9}>Tempo Truck no CD</option>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  style={{
                    width: 590,
                    marginTop: 25,
                    //marginRight: 40,
                  }}
                  id="outlined-uncontrolled"
                  multiline
                  type="text"
                  variant="outlined"
                  label="Descreva a Mensagem"
                  name="descricaomensagem"
                  value={descricaomensagem}
                  onChange={this.changeHandler}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 55,
                  //backgroundColor: 'red',
                }}
              >
                <Button
                  style={{
                    marginTop: 50,
                    width: 260,
                    marginBottom: 10,
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Salvar
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </div>
    );
  }
}

export default mesagem;
