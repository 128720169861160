export const Cores = {
  bgBotoesNovo: "#2d5086",
  clBotoesNovo: "#E6E6E6",
  bgBotoesExcluir: "#e57373",
  clBotoesExcluir: "#E6E6E6",
  subcabecalho: "#d8d8d8",
  bgInpubase: "#E4E7E8",
  bgBotoesNovo1: "#40bf80",
  Azul1: "#2054a8",
  Azul2: "#113164",
  Amarelo1: "#DA9E2F",
  Vermelho1: "#E51C44",
  Vermelho2: "#b71c1c",
  Verde: "#158960",
  Verde1: "#008000",
  Verde2: "#469536",
  Verde3: "#6eaa5e",
  Branco1: "#FFFFFF",
  Sinza1: "#666666",
  Sinza2: "#cccccc",
};
