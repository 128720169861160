import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EditIcon from "@material-ui/icons/Edit";

import AddCircleIcon from "@material-ui/icons/AddCircle";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import api from "../../api";
import {
  deleteProgramacaocarga,
  getProgramacaocarga,
} from "../../service/CRUD_Service";
import { logout, getTipoUsuario } from "../../service/authService";

import { Cores } from "../../style/Color";

export default function ListadeCargas() {
  const history = useHistory("");
  const [cargas, setCargas] = useState([]); //recebe do banco de dados
  const [ent, setEnt] = useState({});
  const classes = useRowStyles();
  const [loading, setLoading] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [searchMotoristas, setSearchMotoristas] = useState("");
  const [searchPlacaCavalo, setSearchPlacaCavalo] = useState("");
  const [searchPlacaCarreta, setSearchPlacaCarreta] = useState("");
  const [openExcluir, setOpenExcluir] = React.useState(false); //para botao  excluir apenas para visualzar o que esta escrito
  const [nameError, setNameError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [operror, setError] = React.useState(false);

  // Função para limpar os filtros
  const handleClearFilters = () => {
    setSearchId("");
    setSearchMotoristas("");
    setSearchPlacaCavalo("");
    setSearchPlacaCarreta("");
  };
  useEffect(() => {
    reloadProgramacao();
  }, []);

  const reloadProgramacao = () => {
    setLoading(true);
    getProgramacaocarga()
      .then((res) => {
        setCargas(res);
        setLoading(false);
      })
      .catch((err) => setNameError(err));
  };

  //PASSA PARA BOTA EXCLUSAO
  const onDelete = (id) => {
    deleteProgramacaocarga(id)
      .then(() => {
        reloadProgramacao();
      })
      .catch((err) => setNameError(err));
  };

  //handle de open
  const handleExcluirClose = () => {
    setOpenExcluir(false);
  };

  const handleExcluirOpen = () => {
    setOpenExcluir(true);
  };
  const handleClickerror = () => {
    setError(true);
  };

  const handleCloseerror = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fetchData = () => {
    api
      .get("programacaocarga")
      .then((res) => {
        let carg = res.data;

        setCargas(carg);
      })
      .catch(() => alert("Erro ao carregar"));
  };

  // useEffect para buscar os dados ao carregar o componente
  useEffect(() => {
    fetchData();
  }, []);

  //EDITE VEICULO
  const onEdit = (id) => {
    history.push("/ProgramacaocargaEdit", { id });
  };

  const filtrarDados = (dados) => {
    return dados.filter((item) => {
      const matchId = searchId === "" || item.id.toString().includes(searchId);
      const matchMotoristas =
        searchMotoristas === "" ||
        (item.idmotoristas.nome &&
          item.idmotoristas.nome
            .toLowerCase()
            .includes(searchMotoristas.toLowerCase()));
      const matchPlacaCavalo =
        searchPlacaCavalo === "" ||
        (item.veiculotrator.placa &&
          item.veiculotrator.placa
            .toLowerCase()
            .includes(searchPlacaCavalo.toLowerCase()));
      const matchPlacaCarreta =
        searchPlacaCarreta === "" ||
        (item.unidadecarga.placa &&
          item.unidadecarga.placa
            .toLowerCase()
            .includes(searchPlacaCarreta.toLowerCase()));

      return (
        matchId && matchMotoristas && matchPlacaCavalo && matchPlacaCarreta
      );
    });
  };

  //FORMATAR DATA
  const formatDate = () => {
    var date = new Date();
    return date;
  };

  //EXIBE DADOS DA TABELA
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [opennt, setOpennt] = useState(false);
    const [openedDeliveryId, setOpenedDeliveryId] = useState(null);
    const classes = useRowStyles();

    const [opencomentario, setOpencomentario] = React.useState(false);

    const handleClickOpen = () => {
      setOpencomentario(true);
    };

    const handleClose = () => {
      setOpencomentario(false);
    };

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            className={classes.txtOperacaotabela}
          >
            {row.id}
          </TableCell>

          <TableCell component="th" scope="row">
            {row.idmotoristas.nome}
          </TableCell>
          {/* {row.fkmotorista.tipo} */}

          <TableCell align="center" component="th">
            {row.veiculotrator.placa}
          </TableCell>
          <TableCell align="center" component="th">
            {row.unidadecarga.placa}
          </TableCell>
          <TableCell align="center" component="th">
            {row.unidadecarga.nfrota}
          </TableCell>
          {(() => {
            // let origem = row.cviagens.fkorigem.descricao;
            let perfil = [...new Set(row.entregas?.map((x) => x.perfil))];

            return (
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.txtOperacaotabela}
              >
                {perfil}
              </TableCell>
            );
          })()}

          {(() => {
            let qtdentrega = [...new Set(row.entregas?.map((x) => x.id))];

            return (
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.txtOperacaotabela}
              >
                {qtdentrega.length}
              </TableCell>
            );
          })()}

          <TableCell align="center">
            <ButtonGroup>
              <IconButton
                aria-label="Editar"
                style={{ width: 25 }}
                color="primary"
              >
                <EditIcon fontSize="small" onClick={() => onEdit(row.id)} />
              </IconButton>

              {/*BOTAO EXCLUIR     */}

              <IconButton
                aria-label="delete"
                style={{ width: 25 }}
                color="secondary"
              >
                <DeleteIcon
                  fontSize="small"
                  className="btexlcuir"
                  onClick={() => {
                    if (window.confirm("Deseja confirmar exclusão"))
                      onDelete(row.id);
                  }}
                />
              </IconButton>
            </ButtonGroup>
          </TableCell>
        </TableRow>

        {/*EXIBI DETALHES DA CARGA */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Detalhes
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Origem</TableCell>
                      <TableCell align="center">Destino</TableCell>
                      <TableCell align="center">Numero Loja</TableCell>
                      <TableCell align="center">Perfil</TableCell>
                      <TableCell align="center">Qtd Pallet</TableCell>

                      <TableCell align="center">Cidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* segundo map */}

                    {row.entregas?.map((valor) => (
                      <TableRow>
                        <TableCell align="center" component="th" scope="row">
                          {valor.origem.descricao}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {valor.destinos?.razao}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {valor.destinos?.lojas}
                        </TableCell>
                        <TableCell align="center" component="th">
                          {valor.perfil}
                        </TableCell>
                        <TableCell align="center" component="th">
                          {valor.qtdpallet}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {valor.destinos?.municipio}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const dadosFiltrados = filtrarDados(cargas);

  return (
    <div>
      <div>
        <AppBar position="sticky" color="default">
          <Toolbar>
            <Typography variant="h6" noWrap>
              Lista de Cargas Programadas
            </Typography>

            <ListItem
              button
              style={{
                width: 25,
                height: 25,
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                onClick={logout}
                style={{
                  justifyItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ExitToAppIcon style={{ color: "#192d4d" }} />
              </ListItemIcon>
            </ListItem>
          </Toolbar>
        </AppBar>
        <br></br>
      </div>
      <Paper
        style={{
          height: 50,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 10,
          marginBottom: 10,
        }}
      >
        {/* InputBase para cada atributo de pesquisa */}
        <InputBase
          style={{
            marginBlockEnd: 5,

            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />

        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar motoristas"
          value={searchMotoristas}
          onChange={(e) => setSearchMotoristas(e.target.value)}
        />

        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar Placa cavalo"
          value={searchPlacaCavalo}
          onChange={(e) => setSearchPlacaCavalo(e.target.value)}
        />
        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar Placa carreta"
          value={searchPlacaCarreta}
          onChange={(e) => setSearchPlacaCarreta(e.target.value)}
        />

        {/* Botão para limpar filtros */}
        <Button
          variant="contained"
          onClick={handleClearFilters}
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            height: 35,
            width: 150,
            backgroundColor: Cores.bgBotoesExcluir,
            color: Cores.clBotoesExcluir,
            fontSize: 12,
          }}
        >
          Limpar Filtros
        </Button>
        {/* Botão para limpar filtros */}
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          href="/ProgramacaoCarga"
          onClick={handleClearFilters}
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            height: 35,
            width: 150,
            backgroundColor: Cores.bgBotoesNovo,
            color: Cores.clBotoesNovo,
            fontSize: 12,
          }}
        >
          Cadastrar
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell />
              <TableCell className={classes.txtheard}>ID</TableCell>
              <TableCell className={classes.txtheard}>Motoristas</TableCell>

              <TableCell align="center" className={classes.txtheard}>
                Placa Cavalo
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Placa Carreta
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                SR
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Perfil carga
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Qtd Entrega
              </TableCell>

              <TableCell align="center" className={classes.txtheard}>
                Opcao
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dadosFiltrados.map((row) => (
              <Row row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  head: {
    backgroundColor: "#FFFFFF",
  },
  txtheard: {
    fontWeight: "bold",
  },
  txtOperacaotabela: {
    fontSize: 16,
    color: "#bf4040",
    fontFamily: "inherit",
    fontWeight: "bold",
  },
});
