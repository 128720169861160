//FALTA FAZER//
//1 COLOCAR UPERCASE NO CAMPO PLACA(CAIXA ALTA)
//
//COMPONENTES MATERIAL
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
import { BsSearch } from "react-icons/bs";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import React, { Component } from "react";
import api from "../api";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { colors, Modal } from "@material-ui/core"; // Exemplo com Material-UI

import TextField from "@material-ui/core/TextField";

import Style_login from "../style/style_login.css";
import { Cores } from "../style/Color";

let Perfil = [
  {
    id: "1",
    perfil: "Normal",
  },
  {
    id: "2",
    perfil: "Horti",
  },
  {
    id: "3",
    perfil: "Frios",
  },
  {
    id: "4",
    perfil: "Panificacao Frios",
  },
  {
    id: "5",
    perfil: "Panificacao Seca",
  },
];

class ProgramacaoCarga extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idprogramacao: "",
      idmotorista: "",
      nomeMotorista: "",
      idveiculotrator: "",
      nomeVeiculoTrator: "",
      idunidadecarga: "",
      nomeunidadecarga: "",
      names: [], // Armazena os dados retornados da API
      veiculotrator: [],
      unidadeCarga: [],
      listaEntregas: [],
      origem: [],
      idorigem: "",
      nomeOrigem: "",
      destinos: [],
      iddestinos: "",
      nomedestinos: "",
      perfil: [],
      idperfil: "",
      nomePerfil: "",
      qtdpl: "",
      loading: true, // Para exibir carregamento enquanto a API responde
      showModal: false, // Controla a visibilidade do modal
      modalVeiculoAberto: false, // Controle do modal de veículo
      showmodalunidadecarga: false,
      showModalOrigem: false,
      showModalDestinos: false,
      showModalPerfil: false,
      filtroPlaca: "",
      filtroUnidadecarga: "",
      filtramotorista: "",
      filtroOrigem: "",
      filtroDestinos: "",
      filtroPerfil: "",
      openSnackbar: false,
      openErrorSnackbar: false,
    };
  }

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // Redirecionar após o tempo do Snackbar
    setTimeout(() => {
      window.location = "/ListaProgramacao"; // Redireciona para a página desejada
    }, 2000); // Tempo de duração do Snackbar (em milissegundos)

    this.setState({ openSnackbar: false });
  };

  handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openErrorSnackbar: false });
  };

  // Manipulador para preencher a lista
  preencherLista = () => {
    const {
      idorigem,
      nomeOrigem,
      iddestinos,
      nomedestinos,
      nomePerfil,
      qtdpl,
      listaEntregas,
    } = this.state;

    if (nomeOrigem && idorigem) {
      // Novo item a ser adicionado à lista
      const novoItem = {
        id: listaEntregas.length + 1, // Gera um ID com base no tamanho atual da lista
        nomeOrigem: nomeOrigem,
        nomedestinos: nomedestinos,
        nomePerfil: nomePerfil,
        qtdpl: qtdpl,
        idorigem: idorigem,
        iddestinos: iddestinos,
      };

      // Adiciona o novo item à lista existente
      this.setState({
        listaEntregas: [...listaEntregas, novoItem],
      });
    } else {
      alert("Por favor, selecione a placa e o motorista antes de prosseguir.");
    }
  };

  removerItem = (id) => {
    const { listaEntregas } = this.state;
    const novaLista = listaEntregas.filter((item) => item.id !== id);
    this.setState({ listaEntregas: novaLista });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = async (e) => {
    e.preventDefault();

    // Verificar se há entregas na listaEntregas
    if (!this.state.listaEntregas || this.state.listaEntregas.length === 0) {
      this.setState({ openErrorSnackbar: true });
      return;
    }

    try {
      // Criar o payload principal para a programação de carga
      const payloadCarga = {
        idmotorista: this.state.idmotorista,
        idveiculotrator: this.state.idveiculotrator,
        idunidadecarga: this.state.idunidadecarga,
      };

      // Enviar a programação de carga e obter o ID retornado
      const responseCarga = await api.post("/programacaocarga", payloadCarga);
      const idProgramacaoCarga = responseCarga.data.id; // Ajuste conforme a resposta da API

      // Criar payloads para as entregas na listaEntregas
      const entregasPayload = this.state.listaEntregas.map((entrega) => ({
        fkprogramacaocarga: idProgramacaoCarga,
        idorigem: entrega.idorigem,
        iddestino: entrega.iddestinos,
        perfil: entrega.nomePerfil,
        qtdpallet: entrega.qtdpl,
      }));

      // Enviar todas as entregas (possivelmente com várias requisições POST)
      for (const entrega of entregasPayload) {
        await api.post("/programacaoentregas", entrega);
      }

      // Abrir o Snackbar ao concluir com sucesso
      this.setState({ openSnackbar: true });
    } catch (error) {
      console.error(
        "Erro ao salvar a programação de carga ou entregas:",
        error
      );
      alert("Erro ao salvar. Verifique os dados e tente novamente.");
    }
  };

  fetchMotoristas = () => {
    this.setState({ loading: true }); // Inicia carregamento
    api
      .get("/MotoristamanutencaoControllers")
      .then((res) => {
        this.setState({ names: res.data, loading: false, showModal: true }); // Exibe modal após carregar os dados
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false }); // Fecha o modal
  };

  handleSelectMotorista = (id, nome) => {
    // Atualiza o estado com o motorista selecionado
    this.setState({
      idmotorista: id,
      nomeMotorista: nome,
      showModal: false, // Fecha o modal
    });
  };

  fetchVeiculotrator = () => {
    this.setState({ loading: true }); // Inicia carregamento
    api
      .get("/veiculos")
      .then((res) => {
        this.setState({
          veiculotrator: res.data,
          loading: false,
          modalVeiculoAberto: true,
        }); // Exibe modal após carregar os dados
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleCloseModalVeiculotrator = () => {
    this.setState({ modalVeiculoAberto: false }); // Fecha o modal
  };

  handleSelectVeiculotrator = (id, nome) => {
    // Atualiza o estado com o motorista selecionado
    this.setState({
      idveiculotrator: id,
      nomeVeiculoTrator: nome,
      modalVeiculoAberto: false, // Fecha o modal
    });
  };

  fetchVeiculounidadecarga = () => {
    this.setState({ loading: true }); // Inicia carregamento
    api
      .get("/veiculos")
      .then((res) => {
        this.setState({
          unidadeCarga: res.data,
          loading: false,
          showmodalunidadecarga: true,
        }); // Exibe modal após carregar os dados
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleCloseModalunidadecarga = () => {
    this.setState({ showmodalunidadecarga: false }); // Fecha o modal
  };

  handleSelectunidadecarga = (id, nome) => {
    // Atualiza o estado com o motorista selecionado
    this.setState({
      idunidadecarga: id,
      nomeunidadecarga: nome,
      showmodalunidadecarga: false, // Fecha o modal
    });
  };

  //############################################### ORIGENS ####################
  fetchOrigens = () => {
    this.setState({ loading: true }); // Inicia carregamento
    api
      .get("/origem")
      .then((res) => {
        this.setState({
          origem: res.data,
          loading: false,
          showModalOrigem: true,
        }); // Exibe modal após carregar os dados
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleCloseModalOrigem = () => {
    this.setState({ showModalOrigem: false }); // Fecha o modal
  };

  handleSelectOrigem = (id, nome) => {
    // Atualiza o estado com o motorista selecionado
    this.setState({
      idorigem: id,
      nomeOrigem: nome,
      showModalOrigem: false, // Fecha o modal
    });
  };

  //################################### FIM ORIGEM ##############################

  //############################################### DESTINOS ####################
  fetchDestinos = () => {
    this.setState({ loading: true }); // Inicia carregamento
    api
      .get("/destino")
      .then((res) => {
        this.setState({
          destinos: res.data,
          loading: false,
          showModalDestinos: true,
        }); // Exibe modal após carregar os dados
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleCloseModalDestinos = () => {
    this.setState({ showModalDestinos: false }); // Fecha o modal
  };

  handleSelectDestinos = (id, nome) => {
    // Atualiza o estado com o motorista selecionado
    this.setState({
      iddestinos: id,
      nomedestinos: nome,
      showModalDestinos: false, // Fecha o modal
    });
  };

  //################################### FIM DESTINOS ##############################

  //################################### PERFIL      ###############################
  fetchPerfil = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({
        perfil: Perfil, // Define os dados como sendo o array Perfil
        loading: false,
        showModalPerfil: true, // Exibe modal após carregar os dados
      });
    }, 500);
  };

  handleCloseModalperfil = () => {
    this.setState({ showModalPerfil: false }); // Fecha o modal
  };

  handleSelectPerfil = (id, nome) => {
    // Atualiza o estado com o motorista selecionado
    this.setState({
      idperfil: id,
      nomePerfil: nome,
      showModalPerfil: false, // Fecha o modal
    });
  };

  //################################### FIM PERFIL      ###############################
  render() {
    const {
      idmotorista,
      nomeMotorista,
      names,
      veiculotrator,
      unidadeCarga,
      listaEntregas,
      idveiculotrator,
      nomeVeiculoTrator,
      idunidadecarga,
      nomeunidadecarga,
      perfil,
      idperfil,
      qtdpl,
      nomePerfil,
      showModalPerfil,
      filtroPerfil,
      origem,
      idorigem,
      nomeOrigem,
      showModalOrigem,
      destinos,
      iddestinos,
      nomedestinos,
      filtroOrigem,
      showModalDestinos,
      loading,
      showModal,
      modalVeiculoAberto,
      showmodalunidadecarga,
    } = this.state;

    return (
      <body className={Style_login.body}>
        <div style={{ marginTop: 25, padding: 20 }}>
          <div>
            <Paper
              variant="outlined"
              elevation={4}
              style={{
                padding: 20,
                backgroundColor: "#fff",

                overflow: "hidden", // Evita rolagem externa
                margin: "auto", // Centraliza o Paper na tela
                borderRadius: "8px",
                display: "flex", // Torna o conteúdo interno flexível
                flexDirection: "column", // Organiza os elementos verticalmente
              }}
            >
              <form
                style={
                  {
                    // backgroundColor: "red",
                  }
                }
                onSubmit={this.submitHandler}
              >
                <div
                  style={{
                    justifyItems: "center",
                  }}
                >
                  <Snackbar
                    open={this.state.openSnackbar}
                    autoHideDuration={2000}
                    onClose={this.handleCloseSnackbar}
                  >
                    <Alert
                      onClose={this.handleCloseSnackbar}
                      severity="success"
                      variant="filled"
                      sx={{
                        width: "100%",
                        maxWidth: 300,
                        position: "fixed",
                        left: "50%",
                        bottom: 20,
                        transform: "translateX(-50%)",
                        zIndex: 9999,
                      }}
                    >
                      Processo executado com sucesso!
                    </Alert>
                  </Snackbar>
                  {/* Snackbar de erro */}
                  <Snackbar
                    open={this.state.openErrorSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleCloseErrorSnackbar}
                  >
                    <Alert
                      onClose={this.handleCloseErrorSnackbar}
                      severity="error"
                      variant="filled"
                      sx={{
                        width: "100%",
                        maxWidth: 320,
                        position: "fixed",
                        left: "50%",
                        bottom: 20,
                        transform: "translateX(-50%)",
                        zIndex: 9999,
                      }}
                    >
                      Falta adicionar as entregas...
                    </Alert>
                  </Snackbar>
                  <InputLabel
                    style={{ fontSize: 25 }}
                    id="demo-simple-select-helper-label"
                  >
                    Adicionar Programacao
                  </InputLabel>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15, // Espaçamento entre linhas
                    //  justifyContent: "space-around",
                    marginTop: 35,
                  }}
                >
                  <div
                    style={{
                      display: "flex", // Permite que os itens fiquem lado a lado
                      flexDirection: "row", // Garante alinhamento horizontal
                      gap: 10, // Espaçamento entre os itens
                    }}
                  >
                    <InputBase
                      style={{
                        width: 80,
                        backgroundColor: Cores.bgInpubase,
                        height: 35,
                      }}
                      className="form-control text-center input-text"
                      placeholder="00"
                      type="number"
                      name="Motorista"
                      value={idmotorista}
                      onChange={this.changeHandler}
                    />

                    <InputBase
                      style={{
                        width: 370,
                        width: 250,
                        height: 35,
                        backgroundColor: Cores.bgInpubase,
                      }}
                      className="form-control text-center input-text"
                      placeholder="Selecione um motorista"
                      value={nomeMotorista}
                      InputProps={{
                        readOnly: true, // Campo somente leitura
                      }}
                    />

                    {/* Botão para abrir o modal */}
                    <Button
                      variant="contained"
                      onClick={this.fetchMotoristas}
                      style={{
                        marginLeft: -6,
                        height: 34,
                        backgroundColor: Cores.bgBotoesNovo,
                        color: Cores.clBotoesNovo,
                      }}
                    >
                      <BsSearch
                        color={Cores.clBotoesNovo}
                        style={{ fontSize: 20 }}
                      />
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15, // Espaçamento entre linhas
                    //  justifyContent: "space-around",

                    marginTop: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex", // Permite que os itens fiquem lado a lado
                      flexDirection: "row", // Garante alinhamento horizontal
                      alignItems: "center", // Alinha verticalmente
                      gap: 10, // Espaçamento entre os itens
                    }}
                  >
                    <InputBase
                      style={{
                        width: 80,
                        height: 35,
                        backgroundColor: Cores.bgInpubase,
                      }}
                      placeholder="00"
                      className="form-control text-center input-text"
                      type="number"
                      name="Placa trator"
                      value={idveiculotrator}
                      onChange={this.changeHandler}
                    />

                    {/* Nome do Motorista */}
                    <InputBase
                      style={{
                        width: 250,
                        height: 35,
                        backgroundColor: Cores.bgInpubase,
                      }}
                      className="form-control text-center input-text"
                      placeholder="Selecione um veiculo trator"
                      value={nomeVeiculoTrator}
                      InputProps={{
                        readOnly: true, // Campo somente leitura
                      }}
                    />

                    {/* Botão para abrir o modal */}
                    <Button
                      variant="contained"
                      onClick={this.fetchVeiculotrator}
                      style={{
                        marginLeft: -7,
                        height: 34,
                        backgroundColor: Cores.bgBotoesNovo,
                        color: Cores.clBotoesNovo,
                      }}
                    >
                      <BsSearch
                        color={Cores.clBotoesNovo}
                        style={{ fontSize: 20 }}
                      />
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15, // Espaçamento entre linhas
                    marginTop: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex", // Permite que os itens fiquem lado a lado
                      flexDirection: "row", // Garante alinhamento horizontal
                      alignItems: "center", // Alinha verticalmente
                      gap: 10, // Espaçamento entre os itens
                    }}
                  >
                    <InputBase
                      style={{
                        width: 80,
                        height: 35,

                        backgroundColor: Cores.bgInpubase,
                      }}
                      placeholder="00"
                      className="form-control text-center input-text"
                      type="number"
                      name="Placa Unidade carga"
                      value={idunidadecarga}
                      onChange={this.changeHandler}
                    />
                  </div>

                  <InputBase
                    style={{
                      width: 250,
                      height: 35,
                      marginLeft: -4,
                      backgroundColor: Cores.bgInpubase,
                    }}
                    placeholder="Selecione unidade carga"
                    className="form-control text-center input-text"
                    value={nomeunidadecarga}
                    InputProps={{
                      readOnly: true, // Campo somente leitura
                    }}
                  />

                  {/* Botão para abrir o modal */}
                  <Button
                    onClick={this.fetchVeiculounidadecarga}
                    variant="contained"
                    style={{
                      marginLeft: -13,
                      height: 34,
                      backgroundColor: Cores.bgBotoesNovo,
                      color: Cores.clBotoesNovo,
                    }}
                  >
                    <BsSearch
                      color={Cores.clBotoesNovo}
                      style={{ fontSize: 20 }}
                    />
                  </Button>
                </div>

                <br></br>
                <div style={{ padding: 10 }}>
                  <Paper
                    style={{
                      width: "100%",
                      maxHeight: "100%",
                      minHeight: 350,
                      marginTop: 25,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: Cores.subcabecalho,
                        height: 15,
                        borderTopRightRadius: 5,
                        borderTopLeftRadius: 5,
                      }}
                    ></div>

                    <TableContainer
                      style={{
                        maxHeight: "300px", // Altura máxima definida
                        overflowY: "auto", // Habilita a barra de rolagem vertical
                      }}
                    >
                      <br></br>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#ffff",
                                color: "#333333",
                                fontWeight: "bold",
                              }}
                            >
                              Origem
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#ffff",
                                color: "#333333",
                                fontWeight: "bold",
                              }}
                            >
                              Destino
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#ffff",
                                color: "#333333",
                                fontWeight: "bold",
                              }}
                            >
                              Perfil
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#ffff",
                                color: "#333333",
                                fontWeight: "bold",
                              }}
                            >
                              Qtd Pallet
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#ffff",
                                color: "#333333",
                                fontWeight: "bold",
                              }}
                            >
                              Opcoes
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {listaEntregas.map((item, id) => (
                          <TableBody key={item.id}>
                            <TableRow role="checkbox" key={item.id}>
                              <TableCell align="center">
                                {item.nomeOrigem}
                              </TableCell>
                              <TableCell align="center">
                                {item.nomedestinos}
                              </TableCell>
                              <TableCell align="center">
                                {item.nomePerfil}
                              </TableCell>
                              <TableCell align="center">{item.qtdpl}</TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  onClick={() => this.removerItem(item.id)}
                                  style={{
                                    backgroundColor: Cores.bgBotoesExcluir,
                                    color: Cores.clBotoesExcluir,
                                    width: 150,
                                  }}
                                >
                                  Excluir
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))}
                      </Table>
                    </TableContainer>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 15, // Espaçamento entre linhas
                        justifyContent: "space-around",

                        marginTop: 15,
                      }}
                    >
                      {/* Primeira linha de itens */}
                      <div
                        style={{
                          display: "flex", // Permite que os itens fiquem lado a lado
                          flexDirection: "row", // Garante alinhamento horizontal
                          alignItems: "center", // Alinha verticalmente
                          gap: 10, // Espaçamento entre os itens
                        }}
                      >
                        <InputBase
                          style={{
                            color: "default",
                            height: 35,
                            width: 200,
                            backgroundColor: Cores.bgInpubase,
                          }}
                          type="text"
                          className="form-control text-center input-text"
                          placeholder="Selecione uma origem"
                          value={nomeOrigem}
                          InputProps={{
                            readOnly: true, // Campo somente leitura
                          }}
                        />
                        <BsSearch
                          color={Cores.bgBotoesNovo}
                          onClick={this.fetchOrigens}
                          style={{ fontSize: 20 }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex", // Permite que os itens fiquem lado a lado
                          flexDirection: "row", // Garante alinhamento horizontal
                          alignItems: "center", // Alinha verticalmente
                          gap: 10, // Espaçamento entre os itens
                          // width: 200,
                        }}
                      >
                        <InputBase
                          style={{
                            color: "default",
                            height: 35,
                            width: 250,
                            backgroundColor: Cores.bgInpubase,
                          }}
                          type="text"
                          className="form-control text-center input-text"
                          placeholder="Selecione uma destino"
                          value={nomedestinos}
                          InputProps={{
                            readOnly: true, // Campo somente leitura
                          }}
                        />
                        <BsSearch
                          color={Cores.bgBotoesNovo}
                          onClick={this.fetchDestinos}
                          style={{ fontSize: 20 }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex", // Permite que os itens fiquem lado a lado
                          flexDirection: "row", // Garante alinhamento horizontal
                          alignItems: "center", // Alinha verticalmente
                          gap: 10, // Espaçamento entre os itens
                          width: 200,
                        }}
                      >
                        <InputBase
                          style={{
                            color: "default",
                            height: 35,
                            width: 220,
                            backgroundColor: Cores.bgInpubase,
                          }}
                          type="text"
                          className="form-control text-center input-text"
                          placeholder="Selecione uma Perfil"
                          value={nomePerfil}
                          InputProps={{
                            readOnly: true, // Campo somente leitura
                          }}
                        />
                        <BsSearch
                          color={Cores.bgBotoesNovo}
                          onClick={this.fetchPerfil}
                          style={{ fontSize: 20 }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex", // Permite que os itens fiquem lado a lado
                          flexDirection: "row", // Garante alinhamento horizontal
                          alignItems: "center", // Alinha verticalmente
                          gap: 10, // Espaçamento entre os itens
                          width: 200,
                        }}
                      >
                        <InputBase
                          style={{
                            color: "default",
                            height: 35,
                            width: 200,
                            backgroundColor: Cores.bgInpubase,
                          }}
                          type="number"
                          className="form-control text-center input-text"
                          placeholder="Digite Qtd Pl"
                          name="qtdpl"
                          value={qtdpl}
                          onChange={this.changeHandler}
                        />
                      </div>

                      {/* Botão  */}
                      <Button
                        variant="contained"
                        onClick={this.preencherLista}
                        style={{
                          backgroundColor: Cores.bgBotoesNovo1,
                          color: Cores.clBotoesNovo,
                          marginRight: 100,
                          width: 150,
                        }}
                      >
                        Adicionar
                      </Button>
                    </div>
                    <div
                      style={{
                        marginTop: 100,

                        display: "flex", // Ativa o Flexbox
                        justifyContent: "center", // Alinha horizontalmente
                        alignItems: "center", // Alinha verticalmente
                        height: "100px",
                      }}
                    >
                      <Button
                        style={{
                          height: 30,
                          backgroundColor: Cores.bgBotoesNovo,
                          color: Cores.clBotoesNovo,
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Salvar Programacao
                      </Button>
                    </div>
                  </Paper>
                </div>
                <br></br>
                <br></br>
              </form>
            </Paper>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            {/* Modal MOTORISTA */}
            <Modal
              open={showModal}
              onClose={() => this.setState({ showModal: false })}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                style={{
                  padding: 20,
                  maxWidth: 600,
                  backgroundColor: "#ffffff",
                }}
              >
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",

                      maxHeight: "70vh", // Limita a altura máxima do modal
                      overflowY: "auto", // Adiciona barra de rolagem vertical
                      width: "100%", // Largura ajustável do modal
                      borderRadius: "8px", // Estilo arredondado opcional
                    }}
                  >
                    <h2>Lista de Motoristas</h2>
                    {/* Campo de pesquisa */}
                    <TextField
                      label="Pesquisar placa"
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ marginBottom: 20 }}
                      onChange={(e) =>
                        this.setState({ filtramotorista: e.target.value })
                      }
                    />
                    <ul>
                      {names
                        .filter((motorista) =>
                          motorista.nome
                            .toLowerCase()
                            .includes(
                              (this.state.filtramotorista || "").toLowerCase()
                            )
                        )
                        .map((motorista) => (
                          <li
                            key={motorista.id}
                            style={{
                              cursor: "pointer",
                              padding: "10px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                            onClick={() =>
                              this.handleSelectMotorista(
                                motorista.id,
                                motorista.nome
                              )
                            }
                          >
                            {motorista.nome} - {motorista.cpf}
                          </li>
                        ))}
                    </ul>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ showModal: false })}
                    >
                      Fechar
                    </Button>
                  </div>
                )}
              </Paper>
            </Modal>
            {/* Modal Veiculo trator */}
            <Modal
              open={modalVeiculoAberto}
              onClose={() => this.setState({ modalVeiculoAberto: false })}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                style={{
                  padding: 20,
                  maxWidth: 600,
                  backgroundColor: "#ffffff",
                }}
              >
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",

                      maxHeight: "70vh", // Limita a altura máxima do modal
                      overflowY: "auto", // Adiciona barra de rolagem vertical
                      width: "100%", // Largura ajustável do modal

                      borderRadius: "8px", // Estilo arredondado opcional
                    }}
                  >
                    <h2>Lista de Veiculos</h2>
                    {/* Campo de pesquisa */}
                    <TextField
                      label="Pesquisar placa"
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ marginBottom: 20 }}
                      onChange={(e) =>
                        this.setState({ filtroPlaca: e.target.value })
                      }
                    />
                    <ul>
                      {veiculotrator
                        .filter((veiculo) =>
                          veiculo.placa
                            .toLowerCase()
                            .includes(
                              (this.state.filtroPlaca || "").toLowerCase()
                            )
                        )
                        .map((placa) => (
                          <li
                            key={placa.id}
                            style={{
                              cursor: "pointer",
                              padding: "10px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                            onClick={() =>
                              this.handleSelectVeiculotrator(
                                placa.id,
                                placa.placa
                              )
                            }
                          >
                            Placa :{placa.placa} - Numero frota :{placa.nfrota}
                          </li>
                        ))}
                    </ul>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        this.setState({ modalVeiculoAberto: false })
                      }
                    >
                      Fechar
                    </Button>
                  </div>
                )}
              </Paper>
            </Modal>
            {/* Modal Veiculo unidade carga */}
            <Modal
              open={showmodalunidadecarga}
              onClose={() => this.setState({ showmodalunidadecarga: false })}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                style={{
                  padding: 20,
                  maxWidth: 600,
                  backgroundColor: "#ffffff",
                }}
              >
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      maxHeight: "70vh", // Limita a altura máxima do modal
                      overflowY: "auto", // Adiciona barra de rolagem vertical
                      width: "100%", // Largura ajustável do modal

                      borderRadius: "8px", // Estilo arredondado opcional
                    }}
                  >
                    <h2>Lista de Veiculos</h2>
                    {/* Campo de pesquisa */}
                    <TextField
                      label="Pesquisar placa"
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ marginBottom: 20 }}
                      onChange={(e) =>
                        this.setState({ filtroUnidadecarga: e.target.value })
                      }
                    />
                    <ul>
                      {unidadeCarga
                        .filter((veiculo) =>
                          veiculo.placa
                            .toLowerCase()
                            .includes(
                              (this.state.filtroPlaca || "").toLowerCase()
                            )
                        )
                        .map((placa) => (
                          <li
                            key={placa.id}
                            style={{
                              cursor: "pointer",
                              padding: "10px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                            onClick={() =>
                              this.handleSelectunidadecarga(
                                placa.id,
                                placa.placa
                              )
                            }
                          >
                            Placa :{placa.placa} - Numero frota :{placa.nfrota}
                          </li>
                        ))}
                    </ul>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        this.setState({ showmodalunidadecarga: false })
                      }
                    >
                      Fechar
                    </Button>
                  </div>
                )}
              </Paper>
            </Modal>

            {/* Modal   ORIGEM */}
            <Modal
              open={showModalOrigem}
              onClose={() => this.setState({ showModalOrigem: false })}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                style={{
                  padding: 20,
                  maxWidth: 600,
                  backgroundColor: "#ffffff",
                }}
              >
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      maxHeight: "70vh", // Limita a altura máxima do modal
                      overflowY: "auto", // Adiciona barra de rolagem vertical
                      width: "100%", // Largura ajustável do modal

                      borderRadius: "8px", // Estilo arredondado opcional
                    }}
                  >
                    <h2>Lista de Origens</h2>
                    {/* Campo de pesquisa */}
                    <TextField
                      label="Pesquisar origem"
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ marginBottom: 20 }}
                      onChange={(e) =>
                        this.setState({ filtroOrigem: e.target.value })
                      }
                    />
                    <ul>
                      {origem
                        .filter((item) =>
                          item.descricao
                            .toLowerCase()
                            .includes(
                              (this.state.filtroOrigem || "").toLowerCase()
                            )
                        )
                        .map((item) => (
                          <li
                            key={item.id}
                            style={{
                              cursor: "pointer",
                              padding: "10px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                            onClick={() =>
                              this.handleSelectOrigem(item.id, item.descricao)
                            }
                          >
                            Descricao :{item.descricao} - Razao:{item.razao}
                          </li>
                        ))}
                    </ul>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ showModalOrigem: false })}
                    >
                      Fechar
                    </Button>
                  </div>
                )}
              </Paper>
            </Modal>
            {/* Modal   DESTINOS */}
            <Modal
              open={showModalDestinos}
              onClose={() => this.setState({ showModalDestinos: false })}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                style={{
                  padding: 20,
                  maxWidth: 600,
                  backgroundColor: "#ffffff",
                }}
              >
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      maxHeight: "70vh", // Limita a altura máxima do modal
                      overflowY: "auto", // Adiciona barra de rolagem vertical
                      width: "100%", // Largura ajustável do modal
                      borderRadius: "8px", // Estilo arredondado opcional
                    }}
                  >
                    <h2>Lista de Destinos</h2>
                    {/* Campo de pesquisa */}
                    <TextField
                      label="Pesquisar Destinos"
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ marginBottom: 20 }}
                      onChange={(e) =>
                        this.setState({ filtroDestinos: e.target.value })
                      }
                    />
                    <ul>
                      {destinos
                        .filter((item) =>
                          item.razao
                            .toLowerCase()
                            .includes(
                              (this.state.filtroDestinos || "").toLowerCase()
                            )
                        )
                        .map((item) => (
                          <li
                            key={item.id}
                            style={{
                              cursor: "pointer",
                              padding: "10px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                            onClick={() =>
                              this.handleSelectDestinos(item.id, item.razao)
                            }
                          >
                            Lojas :{item.lojas} - Razao:{item.razao}
                          </li>
                        ))}
                    </ul>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        this.setState({ showModalDestinos: false })
                      }
                    >
                      Fechar
                    </Button>
                  </div>
                )}
              </Paper>
            </Modal>

            {/* Modal   PERFIL */}
            <Modal
              open={showModalPerfil}
              onClose={() => this.setState({ showModalPerfil: false })}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                style={{
                  padding: 20,
                  maxWidth: 600,
                  backgroundColor: "#ffffff",
                }}
              >
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      maxHeight: "70vh", // Limita a altura máxima do modal
                      overflowY: "auto", // Adiciona barra de rolagem vertical
                      width: "100%", // Largura ajustável do modal
                      borderRadius: "8px", // Estilo arredondado opcional
                    }}
                  >
                    <h2>Lista de perfil</h2>
                    {/* Campo de pesquisa */}
                    <TextField
                      label="Pesquisar Destinos"
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ marginBottom: 20 }}
                      onChange={(e) =>
                        this.setState({ filtroPerfil: e.target.value })
                      }
                    />
                    <ul>
                      {perfil
                        .filter((item) =>
                          item.perfil
                            .toLowerCase()
                            .includes(
                              (this.state.filtroPerfil || "").toLowerCase()
                            )
                        )
                        .map((item) => (
                          <li
                            key={item.id}
                            style={{
                              cursor: "pointer",
                              padding: "10px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                            onClick={() =>
                              this.handleSelectPerfil(item.id, item.perfil)
                            }
                          >
                            Perfil :{item.perfil}
                          </li>
                        ))}
                    </ul>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ showModalPerfil: false })}
                    >
                      Fechar
                    </Button>
                  </div>
                )}
              </Paper>
            </Modal>
          </div>
        </div>
      </body>
    );
  }
}

export default ProgramacaoCarga;
