import React, { useState, useEffect } from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";

import { putstatuscd, getStatuscdid } from "../service/CRUD_Service";

import Paper from "@material-ui/core/Paper";

import Typography from "@material-ui/core/Typography";

import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

export default function StatuscdEdit({ history }) {
  //class updateveiculo extends Component{
  const { id } = history.location.state;

  const [motErro, setMotErro] = useState("");

  const [dados, setDados] = useState({});
  const [Placa, setPlaca] = useState();
  const [idplaca, setIdplaca] = useState();
  const [nfrota, setNfrota] = useState();
  const [status, setStatus] = useState("");
  const [descricao, setDescricao] = useState("");
  const [idcarga, setIdcarga] = useState();
  const [dataentrada, setDataentrada] = useState("");
  const [datasaida, setDatasaida] = useState();
  const [datastatus, setDatastatus] = useState();
  const [dataretorno, setDataretorno] = useState();

  // Função para formatar a data corretamente para o datetime-local
  const formatDateTimeLocal = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Retornando no formato "YYYY-MM-DDTHH:MM"
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Função que ajusta a data e hora para o fuso horário correto
  const formatData = (datasaida) => {
    // Ajusta o fuso horário de UTC para UTC -3 (Horário de Brasília)

    return moment(datasaida).utc().format("YYYY-MM-DDTHH:mm");
  };

  // Função para formatar a data corretamente para o datetime-local
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Retornando no formato "YYYY-MM-DDTHH:MM"
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    getStatuscdid(id)
      .then((res) => {
        setDados(res);
        setIdplaca(res.idplaca);
        setPlaca(res.fkplaca.placa);
        setNfrota(res.fkplaca.nfrota);
        setStatus(res.isstatus);
        setDescricao(res.descricaostatus);
        setIdcarga(res.idcarga);
        setDataentrada(formatData(res.dtentrada));
        setDatasaida(formatData(res.dtsaida));
        setDatastatus(res.datastatus);
        setDataretorno(formatData(res.retornobase));
      })
      .catch((err) => setMotErro(err));
  }, [id]);

  //codigo
  const onChangenIdplaca = (event) => {
    setIdplaca(event.target.value);
  };
  const onChangenplaca = (event) => {
    setPlaca(event.target.value);
  };
  // nome
  const onChangenfrota = (event) => {
    setNfrota(event.target.value);
  };
  // cpf
  const onChangestatus = (event) => {
    setStatus(event.target.value);
  };
  //turno
  const onChangedescricao = (event) => {
    setDescricao(event.target.value);
  };
  //tipo
  const onChangeidcarga = (event) => {
    setIdcarga(event.target.value);
  };
  //tipo
  const onChangedataentrada = (event) => {
    setDataentrada(event.target.value);
  };

  const onChangedatasaida = (event) => {
    setDatasaida(event.target.value); // Armazena no estado o valor ajustado para UTC
  };
  const onChangedtstatus = (event) => {
    setDatastatus(event.target.value);
  };

  const onChangedataretorno = (event) => {
    setDataretorno(event.target.value);
  };

  const toEdit = (event) => {
    event.preventDefault();
    let datasaidas = new Date(datasaida); // Exemplo de data
    let dataentradas = new Date(dataentrada); // Exemplo de data

    dados.idplaca = idplaca;
    dados.isstatus = status;
    dados.descricaostatus = descricao;
    dados.idcarga = idcarga;
    dados.dtentrada = dataentradas;
    dados.dtsaida = datasaidas;
    dados.datastatus = datastatus;
    dados.retornobase = dataretorno;

    putstatuscd(id, dados)
      .then(() => history.push("/Listastatuscd"))
      .catch((err) => setMotErro(err));
  };

  return (
    <div className="Users">
      <Paper
        square
        elevation={4}
        style={{
          backgroundColor: "#FFFF",
          marginLeft: 100,
          marginRight: 100,
          marginTop: 50,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#abb8c3",
          borderRadius: 10,
          minWidth: 410,
        }}
      >
        <form>
          <div
            style={{
              display: "flex",
              backgroundColor: "#191E4D",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              height: 50,
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                color: "#cccccc",
              }}
            >
              Editar Status CD
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
                backgroundColor: "#e7e7e7",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                paddingLeft: 5,
                marginTop: 25,
                maxWidth: 110,
                minWidth: 150,
              }}
            >
              <label
                for="example-input"
                style={{ marginbottom: 5, color: "#808080" }}
              >
                Placa:
              </label>
              <input
                type="text"
                style={{
                  color: "#808080",
                  border: "none",
                }}
                value={Placa}
                disabled
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
                backgroundColor: "#e7e7e7",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                paddingLeft: 5,
                marginTop: 25,
                maxWidth: 110,
                minWidth: 150,
                height: 50,
              }}
            >
              <label
                for="example-input"
                style={{ marginbottom: 5, color: "#808080" }}
              >
                Numero frota:
              </label>
              <input
                type="text"
                style={{
                  color: "#808080",
                  border: "none",
                }}
                value={nfrota}
                disabled
              />
            </div>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Status"
                name="status"
                value={status}
                onChange={onChangestatus}
              >
                <MenuItem value="">
                  <em>Status...</em>
                </MenuItem>

                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Descricao
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Descricao"
                name="descricao"
                value={descricao}
                onChange={onChangedescricao}
              >
                <MenuItem value="">
                  <em>Descricao.....</em>
                </MenuItem>

                <MenuItem value={"Saida CD"}>Saida CD</MenuItem>
                <MenuItem value={"Entrada CD"}>Entrada CD</MenuItem>
                <MenuItem value={"Retorno CD"}>Retorno CD</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                flex: 1,
                maxWidth: 110,
                minWidth: 150,
                marginRight: 50,
                backgroundColor: "#e7e7e7",
              }}
              id="filled-helperText"
              label="Id Carga"
              multiline
              margin="dense"
              type="text"
              name="idcarga"
              value={idcarga} // Usar 'value' em vez de 'defaultValue'
              onChange={onChangeidcarga}
              InputLabelProps={{
                shrink: true, // Faz o label ficar sempre em cima
              }}
            />

            <TextField
              id="datetime-local"
              label="Data status"
              variant="filled"
              margin="dense"
              type="datetime-local"
              multiline
              value={datastatus}
              onChange={onChangedtstatus}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginTop: 25,
                minWidth: 250,
              }}
            />

            <TextField
              id="datetime-local"
              label="Data e Hora de Entrada"
              variant="filled"
              margin="dense"
              type="datetime-local"
              value={dataentrada}
              onChange={onChangedataentrada}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginTop: 25,
                minWidth: 350,
              }}
            />

            <TextField
              id="datetime-local"
              label="Data e Hora de Saida"
              variant="filled"
              margin="dense"
              type="datetime-local"
              value={datasaida}
              onChange={onChangedatasaida}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginTop: 25,
                minWidth: 350,
              }}
            />
            <TextField
              id="datetime-local"
              label="Data e Hora do Retorno"
              variant="filled"
              margin="dense"
              type="datetime-local"
              value={dataretorno}
              onChange={onChangedataretorno}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginTop: 25,
                minWidth: 350,
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginTop: 5,
                marginBottom: 30,
                marginTop: 50,
              }}
              variant="contained"
              color="primary"
              onClick={toEdit}
            >
              Salvar
            </Button>
          </div>
        </form>
      </Paper>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}
