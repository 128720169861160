import axios from "axios";
import { getToken } from "../src/service/authService";

const base_uri = process.env.REACT_APP_BASE_URLS_WEBAPP;
//console.log(process.env.REACT_APP_BASE_URLS_WEBAPP);

const api = axios.create({
  baseURL: base_uri,
  //baseURL: "http://localhost:8080/",

  //baseURL: "https://strapikoch.strsistema.log.br",
  //baseURL: "https://strapikoch-dev.strsistema.log.br/",
});

//novo
api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
